import React, { createContext, useState, useContext, ReactNode } from 'react';

export type LoaderNameType =
  | 'global'
  | 'accountDetails'
  | 'accountsTable'
  | 'accountsForm'
  | 'accountsSummaryCard'
  | 'customerDetails'
  | 'customersTable'
  | 'customersForm'
  | 'employeesTable'
  | 'employeesForm'
  | 'inventoriesExpensePieChartCard'
  | 'inventoryDetails'
  | 'inventoriesListCard'
  | 'inventoriesTable'
  | 'inventoriesForm'
  | 'operationsTable'
  | 'operationsForm'
  | 'projectsTable'
  | 'projectsForm'
  | 'remindersTable'
  | 'remindersForm'
  | 'supplierDetails'
  | 'suppliersTable'
  | 'suppliersForm'
  | 'timeSheetsTable'
  | 'timeSheetsForm'
  | 'transactionsTable'
  | 'transactionsForm'
  | 'vehiclesTable'
  | 'vehiclesForm'
  | 'workLogsTable'
  | 'workLogsForm'
  | 'projectDetails'
  | 'projectsSummaryCard'
  | 'projectsStatusPieChartCard'
  | 'projectsExpensePieChartCard'
  | 'operationsListCard'
  | 'operationsPieChartCard'
  | 'projectsListCard'
  | 'remindersListCard'
  | 'transactionsBarChartCard'
  | 'transactionsListCard'
  | 'transactionsPieChartCard'
  | 'creditSummaryCard'
  | 'debitSummaryCard'
  | 'projectFilesCard'
  | 'fileUpload';

export type LoaderProgressType = 'fileUploadProgress';

interface LoaderContextType {
  getLoaderState: (name: LoaderNameType) => boolean;
  getLoaderProgress: (name: LoaderProgressType) => number;
  setLoaderState: (name: LoaderNameType, value: boolean) => void;
  setLoaderProgress: (name: LoaderProgressType, value: number) => void;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loaderStates, setLoaderStates] = useState({
    global: false,
    accountDetails: false,
    accountsTable: false,
    accountsForm: false,
    accountsSummaryCard: false,
    customerDetails: false,
    customersTable: false,
    customersForm: false,
    employeesTable: false,
    employeesForm: false,
    inventoriesExpensePieChartCard: false,
    inventoryDetails: false,
    inventoriesListCard: false,
    inventoriesTable: false,
    inventoriesForm: false,
    operationsTable: false,
    operationsForm: false,
    projectsTable: false,
    projectsForm: false,
    remindersTable: false,
    remindersForm: false,
    supplierDetails: false,
    suppliersTable: false,
    suppliersForm: false,
    timeSheetsTable: false,
    timeSheetsForm: false,
    transactionsTable: false,
    transactionsForm: false,
    vehiclesTable: false,
    vehiclesForm: false,
    workLogsTable: false,
    workLogsForm: false,
    projectDetails: false,
    projectsSummaryCard: false,
    projectsStatusPieChartCard: false,
    projectsExpensePieChartCard: false,
    operationsListCard: false,
    operationsPieChartCard: false,
    projectsListCard: false,
    remindersListCard: false,
    transactionsBarChartCard: false,
    transactionsListCard: false,
    transactionsPieChartCard: false,
    creditSummaryCard: false,
    debitSummaryCard: false,
    projectFilesCard: false,
    fileUpload: false,
  });
  const [loaderProgresses, setLoaderProgresses] = useState({
    fileUploadProgress: 0,
  });

  const getLoaderState = (name: LoaderNameType) => {
    return loaderStates[name] || false;
  };

  const getLoaderProgress = (name: LoaderProgressType) => {
    return loaderProgresses[name] || 0;
  };

  const setLoaderState = (name: LoaderNameType, value: boolean) => {
    if (name == 'fileUpload') {
      setLoaderProgress('fileUploadProgress', 0);
    }
    setLoaderStates(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const setLoaderProgress = (name: LoaderProgressType, value: number) => {
    setLoaderProgresses(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <LoaderContext.Provider
      value={{
        getLoaderState,
        getLoaderProgress,
        setLoaderState,
        setLoaderProgress,
      }}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};
