import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import {
  createVehicle,
  deleteVehicle,
  getVehicles,
  updateVehicle,
  VehicleModel,
} from 'apis/VehicleApi';
import { useLoader } from 'contexts/LoaderContext';
import VehicleForm from './VehicleForm';
import { pageSizeOptions } from 'constants/PaginationOptions';
import {
  CurrentDateUnix,
  DateFormat,
  ParseEpochDateString,
} from 'utils/EpochConverter';
import { useAuth } from 'contexts/AuthContext';
import { formatQuantity } from 'utils/NumberFormatter';
import NoResultsOverlay from 'components/TableGrid/NoResultsOverlay';
import NoRowsOverlay from 'components/TableGrid/NoRowsOverlay';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import { CustomerModel, getCustomers, getSuppliers, SupplierModel } from 'apis';
import TableToolBar from 'components/TableGrid/TableToolBar';
import TableActions from 'components/TableGrid/TableActions';

export type FormModeType = 'create' | 'edit' | 'delete';

const Vehicles = () => {
  const { branch, runWithPermission, hasPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [selectedVehicleID, setSelectedVehicleID] = useState<GridRowId>();
  const [vehiclesData, setVehiclesData] = useState<VehicleModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [customersList, setCustomersList] = useState<CustomerModel[]>([]);
  const [suppliersList, setSuppliersList] = useState<SupplierModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('vehiclesTable', true);
        getVehicles({
          branchId: branch?.id,
          page: page + 1,
          pageSize: pageSize,
          sort: sortColumn + ':' + sortOrder,
          filter: filterField + ':' + filterOperator + ':' + filterValue,
        })
          .then(response => {
            setVehiclesData(response.rows);
            setRowCount(response.total);
          })
          .catch(error => console.error('Error Fetching Time Sheets: ', error))
          .finally(() => setLoaderState('vehiclesTable', false));
      },
      PageId.Vehicles,
      PermissionType.View,
    );
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    Promise.all([
      getCustomers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name,gstIn',
      }),
      getSuppliers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name,gstIn',
      }),
    ]).then(results => {
      setCustomersList(results[0].rows);
      setSuppliersList(results[1].rows);
    });
  }, []);

  const handleAddVehicle = (newVehicle: Partial<VehicleModel>) => {
    setLoaderState('vehiclesForm', true);
    createVehicle(newVehicle as Omit<VehicleModel, 'id'>)
      .then(response => {
        setVehiclesData(prevVehicles => [...prevVehicles, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding time sheet: ', error))
      .finally(() => setLoaderState('vehiclesForm', false));
  };

  const handleEditVehicle = (editedVehicle: Partial<VehicleModel>) => {
    setLoaderState('vehiclesForm', true);
    if (selectedVehicleID) {
      updateVehicle(selectedVehicleID, editedVehicle)
        .then(response => {
          setVehiclesData(
            _.chain(vehiclesData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(vehicles => {
                const index = _.findIndex(
                  vehicles,
                  trans => trans.id === selectedVehicleID,
                );
                if (index !== -1) vehicles[index] = response;
                return vehicles;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing time sheet: ', error))
        .finally(() => setLoaderState('vehiclesForm', false));
    }
  };

  const handleDeleteVehicle = () => {
    if (selectedVehicleID) {
      setLoaderState('vehiclesForm', true);
      deleteVehicle(selectedVehicleID)
        .then(() => {
          setVehiclesData(
            _.reject(vehiclesData, {
              id: selectedVehicleID,
            }) as VehicleModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting time sheet: ', error))
        .finally(() => setLoaderState('vehiclesForm', false));
    }
  };

  const openVehicleForm = () => {
    let title: string;
    let currentVehicle: Partial<VehicleModel> = {
      branchId: branch?.id,
      customerId: 0,
      supplierId: 0,
      name: '',
      registration: '',
      fitness: CurrentDateUnix(),
      insurance: CurrentDateUnix(),
      tax: CurrentDateUnix(),
      permit: CurrentDateUnix(),
      vehicleValue: 0,
      tyreDate: CurrentDateUnix(),
      tyreOdo: 0,
      oilDate: CurrentDateUnix(),
      oilOdo: 0,
      filterDate: CurrentDateUnix(),
      filterOdo: 0,
      greaseDate: CurrentDateUnix(),
      greaseOdo: 0,
      adBlueDate: CurrentDateUnix(),
      adBlueOdo: 0,
      serviceDate: CurrentDateUnix(),
      serviceOdo: 0,
      odometer: 0,
      notes: '',
    };
    let callback: (vehicle: Partial<VehicleModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Vehicle';
        callback = handleAddVehicle;
        break;
      case 'edit':
        title = 'Edit Vehicle';
        callback = handleEditVehicle;
        currentVehicle = _.chain(vehiclesData)
          .find(vehicle => vehicle.id === selectedVehicleID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Vehicle';
        callback = handleDeleteVehicle;
        currentVehicle = _.chain(vehiclesData)
          .find(vehicle => vehicle.id === selectedVehicleID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Vehicle';
        callback = handleAddVehicle;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <VehicleForm
            mode={formMode}
            initFormState={currentVehicle}
            customersList={customersList}
            suppliersList={suppliersList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedVehicleID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedVehicleID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.4,
      type: 'string',
    },
    {
      field: 'registration',
      headerName: 'REGISTRATION',
      flex: 0.4,
      type: 'string',
    },
    {
      field: 'odometer',
      headerName: 'ODOMETER',
      flex: 0.4,
      type: 'number',
      valueGetter: value => value && formatQuantity(value),
    },
    {
      field: 'fitness',
      headerName: 'FITNESS',
      flex: 0.4,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'insurance',
      headerName: 'INSURANCE',
      flex: 0.4,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'tax',
      headerName: 'TAX',
      flex: 0.4,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'serviceDate',
      headerName: 'SERVICE',
      flex: 0.4,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    TableActions({
      pageId: PageId.Vehicles,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Vehicles</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <DataGrid
          loading={getLoaderState('vehiclesTable')}
          rows={vehiclesData}
          columns={_.compact(columns)}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                if (['day'].includes(filterModel.field)) {
                  setFilterValue(ParseEpochDateString(filterModel.value));
                } else {
                  setFilterValue(filterModel.value);
                }
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: TableToolBar as unknown as JSXElementConstructor<
              GridToolbarProps & ToolbarPropsOverrides
            >,
            noResultsOverlay: NoResultsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableRowSelectionOnClick
          slotProps={{
            toolbar: {
              pageId: PageId.Vehicles,
              createCallback: handleOpen,
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Paper>

      {openForm && openVehicleForm()}
    </>
  );
};

export default React.memo(Vehicles);
