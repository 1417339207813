import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';

import { LinearProgress } from '@mui/material';

import DrawerLayout from 'layouts/DrawerLayout/DrawerLayout';
import PrivateRoute from './PrivateRoute';
import { AvailablePages } from 'constants/AvailablePages';
import { Error, NoPermission, NotFound } from 'pages';
import ProjectDetail from 'pages/Projects/ProjectDetail';
import { AuthProvider } from 'contexts/AuthContext';
import CustomerDetail from 'pages/Customers/CustomerDetail';
import SupplierDetail from 'pages/Suppliers/SupplierDetail';
import AccountDetail from 'pages/Accounts/AccountDetail';
import InventoryDetail from 'pages/Inventories/InventoryDetail';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';

const LoginPage = lazy(() => import('pages/Login/Login'));

const AppRouter = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <BrowserRouter>
        <ErrorBoundary fallback={<Error />}>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/app/projects/:id"
                element={
                  <PrivateRoute
                    pageId={PageId.Projects}
                    permissions={[PermissionType.View]}>
                    <ProjectDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/customers/:id"
                element={
                  <PrivateRoute
                    pageId={PageId.Customers}
                    permissions={[PermissionType.View]}>
                    <CustomerDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/suppliers/:id"
                element={
                  <PrivateRoute
                    pageId={PageId.Suppliers}
                    permissions={[PermissionType.View]}>
                    <SupplierDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/inventories/:id"
                element={
                  <PrivateRoute
                    pageId={PageId.Inventories}
                    permissions={[PermissionType.View]}>
                    <InventoryDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/accounts/:id"
                element={
                  <PrivateRoute
                    pageId={PageId.Accounts}
                    permissions={[PermissionType.View]}>
                    <AccountDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/app"
                element={
                  <PrivateRoute>
                    <DrawerLayout />
                  </PrivateRoute>
                }>
                {_.map(
                  _.filter(AvailablePages, { enabled: true }),
                  (page, index) => {
                    return (
                      <Route
                        key={index}
                        path={page.url}
                        element={
                          <PrivateRoute pageId={page.id}>
                            {<page.component />}
                          </PrivateRoute>
                        }
                      />
                    );
                  },
                )}
              </Route>
              <Route path="/noPermission" element={<NoPermission />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
};

export default React.memo(AppRouter);
