import React from 'react';
import { bouncy } from 'ldrs';

import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts';

import { LoaderNameType, useLoader } from 'contexts/LoaderContext';
import { formatCurrencyShortHand } from 'utils/CurrencyFormatter';

interface StackedChartType {
  loaderName: LoaderNameType;
  series1: number[];
  series1Name: string;
  series2: number[];
  series2Name: string;
  xAxis: string[];
}

const StackedChart = (props: StackedChartType) => {
  const theme = useTheme();
  const { getLoaderState } = useLoader();

  bouncy.register();

  return getLoaderState(props.loaderName) ? (
    <Paper
      elevation={0}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <l-bouncy size="45" speed="1.75" color="black"></l-bouncy>
    </Paper>
  ) : (
    <BarChart
      loading={getLoaderState(props.loaderName)}
      series={[
        {
          data: props.series1,
          label: props.series1Name,
          id: 'series1Id',
          stack: 'total',
          color: 'primary.main',
        },
        {
          data: props.series2,
          label: props.series2Name,
          id: 'series2Id',
          stack: 'total',
          color: theme.palette.secondary.light,
        },
      ]}
      margin={{
        left: 40,
        right: 0,
        bottom: 20,
      }}
      yAxis={[
        {
          valueFormatter: value => {
            return formatCurrencyShortHand(value, 0);
          },
        },
      ]}
      xAxis={[
        {
          data: props.xAxis,
          scaleType: 'band',
        },
      ]}
      borderRadius={6}
    />
  );
};

export default React.memo(StackedChart);
