import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import {
  AccountModel,
  createTimeSheet,
  CustomerModel,
  deleteTimeSheet,
  EmployeeModel,
  getAccounts,
  getCustomers,
  getEmployees,
  getInventories,
  getProjects,
  getSuppliers,
  getTimeSheets,
  getVehicles,
  InventoryModel,
  ProjectModel,
  SupplierModel,
  TimeSheetModel,
  updateTimeSheet,
  VehicleModel,
} from 'apis';
import { useLoader } from 'contexts/LoaderContext';
import TimeSheetForm from './TimeSheetForm';
import { pageSizeOptions } from 'constants/PaginationOptions';
import {
  TimeSheetFormStatusOptions,
  TimeSheetFormTypeOptions,
} from 'constants/DropDownOptions';
import {
  CurrentDateUnix,
  DateFormat,
  ParseEpochDateString,
} from 'utils/EpochConverter';
import { useAuth } from 'contexts/AuthContext';
import NoResultsOverlay from 'components/TableGrid/NoResultsOverlay';
import NoRowsOverlay from 'components/TableGrid/NoRowsOverlay';
import { EMPTY_COLUMN } from 'constants/TableConstants';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import TableToolBar from 'components/TableGrid/TableToolBar';
import TableActions from 'components/TableGrid/TableActions';

export type FormModeType = 'create' | 'edit' | 'delete';

const TimeSheets = () => {
  const { branch, employee, runWithPermission, hasPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [selectedTimeSheetID, setSelectedTimeSheetID] = useState<GridRowId>();
  const [timeSheetsData, setTimeSheetsData] = useState<TimeSheetModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [accountsList, setAccountsList] = useState<AccountModel[]>([]);
  const [employeesList, setEmployeesList] = useState<EmployeeModel[]>([]);
  const [projectsList, setProjectsList] = useState<ProjectModel[]>([]);
  const [customersList, setCustomersList] = useState<CustomerModel[]>([]);
  const [suppliersList, setSuppliersList] = useState<SupplierModel[]>([]);
  const [vehiclesList, setVehiclesList] = useState<VehicleModel[]>([]);
  const [inventoriesList, setInventoriesList] = useState<InventoryModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('timeSheetsTable', true);
        getTimeSheets({
          branchId: branch?.id,
          page: page + 1,
          pageSize: pageSize,
          sort: sortColumn + ':' + sortOrder,
          filter: filterField + ':' + filterOperator + ':' + filterValue,
        })
          .then(response => {
            setTimeSheetsData(response.rows);
            setRowCount(response.total);
          })
          .catch(error => console.error('Error Fetching Time Sheets: ', error))
          .finally(() => setLoaderState('timeSheetsTable', false));
      },
      PageId.TimeSheets,
      PermissionType.View,
    );
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    Promise.all([
      getAccounts({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getEmployees({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getProjects({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getCustomers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getSuppliers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getVehicles({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,registration',
      }),
      getInventories({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
    ]).then(results => {
      setAccountsList(results[0].rows);
      setEmployeesList(results[1].rows);
      setProjectsList(results[2].rows);
      setCustomersList(results[3].rows);
      setSuppliersList(results[4].rows);
      setVehiclesList(results[5].rows);
      setInventoriesList(results[6].rows);
    });
  }, []);

  const handleAddTimeSheet = (newTimeSheet: Partial<TimeSheetModel>) => {
    setLoaderState('timeSheetsForm', true);
    createTimeSheet(newTimeSheet as Omit<TimeSheetModel, 'id'>)
      .then(response => {
        setTimeSheetsData(prevTimeSheets => [...prevTimeSheets, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding time sheet: ', error))
      .finally(() => setLoaderState('timeSheetsForm', false));
  };

  const handleEditTimeSheet = (editedTimeSheet: Partial<TimeSheetModel>) => {
    setLoaderState('timeSheetsForm', true);
    if (selectedTimeSheetID) {
      updateTimeSheet(selectedTimeSheetID, editedTimeSheet)
        .then(response => {
          setTimeSheetsData(
            _.chain(timeSheetsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(timeSheets => {
                const index = _.findIndex(
                  timeSheets,
                  trans => trans.id === selectedTimeSheetID,
                );
                if (index !== -1) timeSheets[index] = response;
                return timeSheets;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing time sheet: ', error))
        .finally(() => setLoaderState('timeSheetsForm', false));
    }
  };

  const handleDeleteTimeSheet = () => {
    if (selectedTimeSheetID) {
      setLoaderState('timeSheetsForm', true);
      deleteTimeSheet(selectedTimeSheetID)
        .then(() => {
          setTimeSheetsData(
            _.reject(timeSheetsData, {
              id: selectedTimeSheetID,
            }) as TimeSheetModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting time sheet: ', error))
        .finally(() => setLoaderState('timeSheetsForm', false));
    }
  };

  const openTimeSheetForm = () => {
    let title: string;
    let currentTimeSheet: Partial<TimeSheetModel> = {
      branchId: branch?.id,
      employeeId: employee?.id,
      verifierId: 0,
      accountId: 0,
      projectId: 0,
      customerId: 0,
      supplierId: 0,
      vehicleId: 0,
      inventoryId: 0,
      timeSheetType: '',
      status: '',
      day: CurrentDateUnix(),
      hour0: 0,
      hour1: 0,
      hour2: 0,
      hour3: 0,
      hour4: 0,
      hour5: 0,
      hour6: 0,
      hour7: 0,
      hour8: 0,
      hour9: 0,
      hour10: 0,
      hour11: 0,
      hour12: 0,
      hour13: 0,
      hour14: 0,
      hour15: 0,
      hour16: 0,
      hour17: 0,
      hour18: 0,
      hour19: 0,
      hour20: 0,
      hour21: 0,
      hour22: 0,
      hour23: 0,
      notes: '',
    };
    let callback: (timeSheet: Partial<TimeSheetModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Time Sheet';
        callback = handleAddTimeSheet;
        break;
      case 'edit':
        title = 'Edit Time Sheet';
        callback = handleEditTimeSheet;
        currentTimeSheet = _.chain(timeSheetsData)
          .find(timeSheet => timeSheet.id === selectedTimeSheetID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Time Sheet';
        callback = handleDeleteTimeSheet;
        currentTimeSheet = _.chain(timeSheetsData)
          .find(timeSheet => timeSheet.id === selectedTimeSheetID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Time Sheet';
        callback = handleAddTimeSheet;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <TimeSheetForm
            mode={formMode}
            initFormState={currentTimeSheet}
            accountsList={accountsList}
            employeesList={employeesList}
            projectsList={projectsList}
            customersList={customersList}
            suppliersList={suppliersList}
            vehiclesList={vehiclesList}
            inventoriesList={inventoriesList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedTimeSheetID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedTimeSheetID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    {
      field: 'timeSheetType',
      headerName: 'TYPE',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TimeSheetFormTypeOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(TimeSheetFormTypeOptions, {
          value: value as string,
        });
        return result ? result.label : EMPTY_COLUMN;
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TimeSheetFormStatusOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(TimeSheetFormStatusOptions, {
          value: value as string,
        });
        return result ? result.label : EMPTY_COLUMN;
      },
    },
    {
      field: 'day',
      headerName: 'DAY',
      flex: 0.6,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'employeeId',
      headerName: 'EMPLOYEE',
      flex: 0.6,
      type: 'singleSelect',
      valueOptions: _.map(employeesList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = employeesList.find(record => {
          return record.id == value;
        });
        return result ? result.name : EMPTY_COLUMN;
      },
    },
    {
      field: 'verifierId',
      headerName: 'VERIFIER',
      flex: 0.6,
      type: 'singleSelect',
      valueOptions: _.map(employeesList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = employeesList.find(record => {
          return record.id == value;
        });
        return result ? result.name : EMPTY_COLUMN;
      },
    },
    TableActions({
      pageId: PageId.TimeSheets,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Time Sheets</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <DataGrid
          loading={getLoaderState('timeSheetsTable')}
          rows={timeSheetsData}
          columns={_.compact(columns)}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                if (['day'].includes(filterModel.field)) {
                  setFilterValue(ParseEpochDateString(filterModel.value));
                } else {
                  setFilterValue(filterModel.value);
                }
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: TableToolBar as unknown as JSXElementConstructor<
              GridToolbarProps & ToolbarPropsOverrides
            >,
            noResultsOverlay: NoResultsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableRowSelectionOnClick
          slotProps={{
            toolbar: {
              pageId: PageId.TimeSheets,
              createCallback: handleOpen,
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Paper>

      {openForm && openTimeSheetForm()}
    </>
  );
};

export default React.memo(TimeSheets);
