import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import { Copyright } from 'components/atoms';
import { AlertPopup } from 'components';
import { useLoader } from 'contexts/LoaderContext';

import { LoginReqType, useAuth } from 'contexts/AuthContext';
import { grid } from 'ldrs';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { AvailablePages } from 'constants/AvailablePages';
import { PermissionType } from 'utils/PermissionsConfig';

const Login = () => {
  const { authCheck, login, hasPermission, employee } = useAuth();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [loginData, setLoginData] = useState<LoginReqType>();
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const { setLoaderState } = useLoader();
  const [authLoading, setAuthLoading] = useState(false);

  const navigate = useNavigate();

  grid.register();

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const handleClose = () => {
    setShowErrorMessage(false);
  };

  useEffect(() => {
    if (employee) {
      const home = _.find(AvailablePages, page =>
        hasPermission(page.id, [
          PermissionType.Add,
          PermissionType.View,
          PermissionType.Modify,
          PermissionType.Delete,
        ]),
      );
      navigate(home ? '/app/' + home.url : '/app/dashboard');
    }
  }, [employee]);

  useEffect(() => {
    setAuthLoading(true);
    authCheck()
      .then(response => {
        if (response) {
          /* The useEffect call above, which is linked to the employee object in
          the AuthContext, manages the redirection to the appropriate
          homepage based on the permissions assigned to the user */
        }
      })
      .finally(() => {
        setAuthLoading(false);
      });
  }, []);

  useEffect(() => {
    if (loginData) {
      setLoadingBtn(true);
      setLoaderState('global', true);
      login(loginData)
        .then(response => {
          if (response) {
            /* The useEffect call above, which is linked to the employee object
            in the AuthContext, manages the redirection to the appropriate
            homepage based on the permissions assigned to the user */
          } else {
            setShowErrorMessage(true);
            setErrorMessage('Login failed!');
          }
        })
        .catch(error => {
          setShowErrorMessage(true);
          console.error(error);
          setErrorMessage('Login failed!');
        })
        .finally(() => {
          setLoaderState('global', false);
          setLoadingBtn(false);
        });
    }
  }, [loginData]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // data.set('username', 'demo@habiot.com');
    // data.set('password', 'demo123');
    // data.set('branch', 'DEM');

    setLoginData({
      email: data.get('username'),
      password: data.get('password'),
      branchCode: _.upperCase(data.get('branch') as string),
      deviceId: '*',
    } as LoginReqType);
  };

  if (authLoading) {
    return (
      <Paper
        elevation={0}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <l-grid size="100" speed="2" color="black"></l-grid>
      </Paper>
    );
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.25 }}>
        <Grid
          container
          justifyContent={'center'}
          alignContent={'center'}
          height={'100vh'}
          width={'100vw'}
          sx={{
            background: `url('/images/main-bg.jpeg')`,
            backgroundSize: 'cover',
          }}>
          <Helmet>
            <title>SKC - Login</title>
          </Helmet>
          <Grid item xs={10} md={4} lg={3}>
            <Card
              sx={{
                backdropFilter: 'blur(10px)',
                background: 'rgba(0,0,0,0.1)',
              }}>
              <Grid
                container
                px={{ xs: 3, md: 3, lg: 4 }}
                py={{ xs: 2, md: 2, lg: 3 }}>
                <Grid item xs={12} textAlign={'center'}>
                  <Typography
                    variant="h5"
                    fontWeight={700}
                    mb={1}
                    color={'secondary.main'}>
                    Shree Kumaran Constructions
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                      required
                      margin="normal"
                      fullWidth
                      id="username"
                      color="secondary"
                      label="Username"
                      name="username"
                      autoFocus
                      inputProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                      sx={{
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: 'white',
                        },
                        '& label': {
                          color: 'lightgrey',
                        },
                        '&:hover label': {
                          color: 'white',
                        },
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'lightgrey',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                    />
                    <TextField
                      required
                      margin="normal"
                      fullWidth
                      color="secondary"
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      inputProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end">
                              {showPassword ? (
                                <VisibilityOffIcon color="secondary" />
                              ) : (
                                <VisibilityIcon color="secondary" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: 'white',
                        },
                        '& label': {
                          color: 'lightgrey',
                        },
                        '&:hover label': {
                          color: 'white',
                        },
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'lightgrey',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                    />
                    <TextField
                      required
                      margin="normal"
                      fullWidth
                      name="branch"
                      label="Branch"
                      id="branch"
                      color="secondary"
                      inputProps={{
                        maxLength: 3,
                        style: {
                          color: 'white',
                          textTransform: 'uppercase',
                        },
                      }}
                      sx={{
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: 'white',
                        },
                        '& label': {
                          color: 'lightgrey',
                        },
                        '&:hover label': {
                          color: 'white',
                        },
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'lightgrey',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                    />
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      sx={{ mt: 3, p: 1.5 }}
                      loading={loadingBtn}
                      loadingIndicator="Signing In...">
                      Sign In
                    </LoadingButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Copyright sx={{ mt: 3 }} color={'secondary'} />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {errorMessage && (
            <AlertPopup
              type="error"
              open={showErrorMessage}
              message={errorMessage}
              onClose={handleClose}
            />
          )}
        </Grid>
      </motion.div>
    </AnimatePresence>
  );
};

export default React.memo(Login);
