import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import {
  createInventory,
  deleteInventory,
  InventoryModel,
  getInventories,
  updateInventory,
} from 'apis/InventoryApi';
import { useLoader } from 'contexts/LoaderContext';
import InventoryForm from './InventoryForm';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { useAuth } from 'contexts/AuthContext';
import { CurrentDateUnix, DateFormat } from 'utils/EpochConverter';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { getSuppliers, SupplierModel } from 'apis';
import { formatQuantity } from 'utils/NumberFormatter';
import NoResultsOverlay from 'components/TableGrid/NoResultsOverlay';
import NoRowsOverlay from 'components/TableGrid/NoRowsOverlay';
import { EMPTY_COLUMN } from 'constants/TableConstants';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import TableToolBar from 'components/TableGrid/TableToolBar';
import TableActions from 'components/TableGrid/TableActions';

export type FormModeType = 'create' | 'edit' | 'delete';

const Inventories = () => {
  const { branch, runWithPermission, hasPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [selectedInventoryID, setSelectedInventoryID] = useState<GridRowId>();
  const [inventoriesData, setInventoriesData] = useState<InventoryModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [suppliersList, setSuppliersList] = useState<SupplierModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('inventoriesTable', true);
        getInventories({
          branchId: branch?.id,
          page: page + 1,
          pageSize: pageSize,
          sort: sortColumn + ':' + sortOrder,
          filter: filterField + ':' + filterOperator + ':' + filterValue,
        })
          .then(response => {
            setInventoriesData(response.rows);
            setRowCount(response.total);
          })
          .catch(error => console.error('Error Fetching Inventories: ', error))
          .finally(() => setLoaderState('inventoriesTable', false));
      },
      PageId.Inventories,
      PermissionType.View,
    );
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    Promise.all([
      getSuppliers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
    ]).then(results => {
      setSuppliersList(results[0].rows);
    });
  }, []);

  const handleAddInventory = (newInventory: Partial<InventoryModel>) => {
    setLoaderState('inventoriesForm', true);
    createInventory(newInventory as Omit<InventoryModel, 'id'>)
      .then(response => {
        setInventoriesData(prevInventories => [...prevInventories, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding inventory: ', error))
      .finally(() => setLoaderState('inventoriesForm', false));
  };

  const handleEditInventory = (editedInventory: Partial<InventoryModel>) => {
    setLoaderState('inventoriesForm', true);
    if (selectedInventoryID) {
      updateInventory(selectedInventoryID, editedInventory)
        .then(response => {
          setInventoriesData(
            _.chain(inventoriesData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(inventories => {
                const index = _.findIndex(
                  inventories,
                  acc => acc.id === selectedInventoryID,
                );
                if (index !== -1) inventories[index] = response;
                return inventories;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing inventory: ', error))
        .finally(() => setLoaderState('inventoriesForm', false));
    }
  };

  const handleDeleteInventory = () => {
    if (selectedInventoryID) {
      setLoaderState('inventoriesForm', true);
      deleteInventory(selectedInventoryID)
        .then(() => {
          setInventoriesData(
            _.reject(inventoriesData, {
              id: selectedInventoryID,
            }) as InventoryModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting inventory: ', error))
        .finally(() => setLoaderState('inventoriesForm', false));
    }
  };

  const openInventoryForm = () => {
    let title: string;
    let currentInventory: Partial<InventoryModel> = {
      branchId: branch?.id,
      name: '',
      code: '',
      category: '',
      quantity: 0,
      price: 0,
      cGst: 0,
      sGst: 0,
      iGst: 0,
      limit: 0,
      expiry: CurrentDateUnix(),
      notes: '',
    };
    let callback: (inventory: Partial<InventoryModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Inventory';
        callback = handleAddInventory;
        break;
      case 'edit':
        title = 'Edit Inventory';
        callback = handleEditInventory;
        currentInventory = _.chain(inventoriesData)
          .find(transaction => transaction.id === selectedInventoryID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Inventory';
        callback = handleDeleteInventory;
        currentInventory = _.chain(inventoriesData)
          .find(transaction => transaction.id === selectedInventoryID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Inventory';
        callback = handleAddInventory;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <InventoryForm
            mode={formMode}
            initFormState={currentInventory}
            suppliersList={suppliersList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleView = (id: GridRowId) => {
    const inventory = _.find(inventoriesData, { id: id as number });
    // Securely open a new tab
    const newWindow = window.open(
      `/app/inventories/${id}?supplierId=${inventory?.supplierId}`,
      '_blank',
      'noopener,noreferrer',
    );

    if (newWindow) {
      newWindow.opener = null; // Prevent reverse tabnabbing
    }
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedInventoryID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedInventoryID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'supplierId',
      headerName: 'SUPPLIER',
      flex: 0.6,
      type: 'singleSelect',
      valueOptions: _.map(suppliersList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(suppliersList, record => {
          return record.id == value;
        });
        return result ? result.name : EMPTY_COLUMN;
      },
    },
    {
      field: 'price',
      headerName: 'PRICE',
      flex: 0.4,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'unit',
      headerName: 'UNIT',
      flex: 0.4,
      type: 'string',
    },
    {
      field: 'quantity',
      headerName: 'STOCK',
      flex: 0.2,
      type: 'number',
      valueGetter: value => value && formatQuantity(value),
    },
    {
      field: 'limit',
      headerName: 'LIMIT',
      flex: 0.2,
      type: 'number',
      valueGetter: value => value && formatQuantity(value),
    },
    {
      field: 'expiry',
      headerName: 'EXPIRY',
      flex: 0.6,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    TableActions({
      pageId: PageId.Inventories,
      handleView: handleView,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Inventories</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <DataGrid
          loading={getLoaderState('inventoriesTable')}
          rows={inventoriesData}
          columns={_.compact(columns)}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: TableToolBar as unknown as JSXElementConstructor<
              GridToolbarProps & ToolbarPropsOverrides
            >,
            noResultsOverlay: NoResultsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableRowSelectionOnClick
          slotProps={{
            toolbar: {
              pageId: PageId.Accounts,
              createCallback: handleOpen,
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Paper>

      {openForm && openInventoryForm()}
    </>
  );
};

export default React.memo(Inventories);
