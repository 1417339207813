import { createTheme, Zoom } from '@mui/material';

import { SlideRightTransition } from './motionTransitions';
import lightThemeOptions from './lightTheme';

const theme = createTheme(lightThemeOptions);

const components = {
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiSnackbar: {
      defaultProps: {
        TransitionComponent: SlideRightTransition,
      },
    },
    MuiDialog: {
      defaultProps: {
        TransitionComponent: Zoom,
      },
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
        paperFullScreen: {
          borderRadius: 0,
        },
        root: {
          backdropFilter: 'blur(2px)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[3],
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: 8,
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bolder',
          },
        },
      },
    },
  },
};

export default components;
