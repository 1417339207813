import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

import { Box, Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import { NotificationsActive as NotificationsActiveIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { getReminders, ReminderModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { CalendarFormat, DateTimeFormat } from 'utils/EpochConverter';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';

interface RemindersListCardProps {
  filter: string;
}

const RemindersListCard = (props: RemindersListCardProps) => {
  const theme = useTheme();
  const { branch, runWithPermission } = useAuth();

  const { getLoaderState, setLoaderState } = useLoader();

  const [reminders, setReminders] = useState<ReminderModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('remindersListCard', true);
        getReminders({
          branchId: branch?.id,
          pageSize: 10000,
          fields: 'id,name,time,status,notes',
          sort: 'time:asc',
          filter: props.filter,
        })
          .then(response => {
            setReminders(response.rows);
          })
          .catch(error => console.error('Error loading reminders: ', error))
          .finally(() => setLoaderState('remindersListCard', false));
      },
      PageId.Reminders,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card
      sx={{
        p: { xs: 2, lg: 3 },
        height: 400,
        display: 'grid',
        alignContent: reminders.length == 0 ? 'stretch' : 'flex-start',
      }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Reminders
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <NotificationsActiveIcon fontSize="large" />
          </Icon>
        </Grid>
      </Grid>
      <Grid container overflow="scroll" height="-webkit-fill-available">
        {getLoaderState('remindersListCard')
          ? _.times(5, index => {
              return (
                <Fragment key={index}>
                  <Grid item xs={6} pt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width={250}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1} justifyItems="right">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width={150}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={15}
                      width={200}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1} justifyItems="right">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={15}
                      width={100}
                    />
                  </Grid>
                </Fragment>
              );
            })
          : null}
        {!getLoaderState('remindersListCard') && reminders.length == 0 ? (
          <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <PermissionGuard
              pageId={PageId.Reminders}
              permissionType={PermissionType.View}
              noPermissionComponent={
                <>
                  <img
                    style={{
                      width: '8vw',
                      minWidth: '100px',
                    }}
                    src="/illustrations/noPermission.svg"
                  />
                  <Typography p={3} color="grey">
                    No Permission
                  </Typography>
                </>
              }>
              <img
                style={{
                  width: '8vw',
                  minWidth: '100px',
                }}
                src="/illustrations/noReminders.svg"
              />
              <Typography p={3} color="grey">
                No Reminders
              </Typography>
            </PermissionGuard>
          </Box>
        ) : null}
        {_.map(reminders, reminder => {
          return (
            <Grid container key={reminder.id}>
              <Grid item xs={8} pt={1}>
                <Typography variant="subtitle1">{reminder.name}</Typography>
                <Typography
                  variant="body1"
                  fontSize="0.8rem"
                  color={theme.palette.secondary.light}>
                  {reminder.notes}
                </Typography>
              </Grid>
              <Grid item xs={4} pt={1} textAlign="right">
                <Typography fontWeight={700}>
                  {DateTimeFormat(reminder.time)}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="0.8rem"
                  color={theme.palette.secondary.light}>
                  {CalendarFormat(reminder.time)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default React.memo(RemindersListCard);
