import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedInventoryModel {
  rows: InventoryModel[];
  total: number;
}

export interface InventoryModel {
  id: number;
  branchId: number;
  supplierId: number;
  name: string;
  code: string;
  category: string;
  quantity: number;
  unit: string;
  price: number;
  cGst: number;
  sGst: number;
  iGst: number;
  limit: number;
  expiry: number;
  notes: string;
  'files[]'?: File[];
  createdAt: number;
  updatedAt: number;
}

export const getInventories = async (
  paramObj: object = {},
): Promise<PaginatedInventoryModel> => {
  const response = await axios.get<PaginatedInventoryModel>(`/inventories`, {
    params: paramObj,
  });
  return response.data;
};

export const getInventoryById = async (
  id: number | string,
): Promise<InventoryModel> => {
  const response = await axios.get<InventoryModel>(`/inventories/${id}`);
  return response.data;
};

export const createInventory = async (
  inventoryData: Omit<InventoryModel, 'id'>,
): Promise<InventoryModel> => {
  const response = await axios.post<InventoryModel>(
    `/inventories`,
    inventoryData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const updateInventory = async (
  id: GridRowId,
  inventoryData: Partial<InventoryModel>,
): Promise<InventoryModel> => {
  const response = await axios.put<InventoryModel>(
    `/inventories/${id}`,
    inventoryData,
  );
  return response.data;
};

export const deleteInventory = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/inventories/${id}`);
};
