import React from 'react';

import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import './App.scss';
import AppRouter from './routes/AppRouter';
import { Loader } from 'components';
import { lightTheme } from 'themes';
import { LoaderProvider } from 'contexts/LoaderContext';
import { GlobalProvider } from 'contexts/GlobalContext';

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalProvider>
        <LoaderProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Loader />
            <AppRouter />
          </LocalizationProvider>
        </LoaderProvider>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default React.memo(App);
