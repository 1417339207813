const CurrencyFormatter = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const formatCurrency = (value: number | undefined) => {
  if (value) {
    return CurrencyFormatter.format(value | 0).replace(/^(\D+)/, '$1 ');
  }
  return CurrencyFormatter.format(0).replace(/^(\D+)/, '$1 ');
};

export const formatCurrencyShortHand = (
  value: number | undefined,
  fraction = 2,
) => {
  if (value == null) {
    return CurrencyFormatter.format(0).replace(/^(\D+)/, '$1 ');
  }

  const formatWithFraction = (num: number) => {
    const formatted = num.toFixed(fraction);
    return parseFloat(formatted).toString(); // Removes unnecessary decimals if zero
  };

  if (value >= 1e7) {
    return `₹ ${formatWithFraction(value / 1e7)} Cr`;
  }

  if (value >= 1e5) {
    return `₹ ${formatWithFraction(value / 1e5)} L`;
  }

  if (value >= 1e3) {
    return `₹ ${formatWithFraction(value / 1e3)} K`;
  }

  return CurrencyFormatter.format(value);
};
