export const calculateProgress = (current: number, total: number): number => {
  if (total <= 0) return 0;
  const progress = (current / total) * 100;
  return Math.min(100, Math.max(0, progress));
};

export const calculateRangeProgress = (
  current: number,
  min: number,
  max: number,
): number => {
  const progress = Math.round(((current - min) / (max - min)) * 100);
  return Math.min(100, Math.max(0, progress));
};
