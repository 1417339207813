import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Logout as LogoutIcon,
  Info as InfoIcon,
  Assessment as SummaryIcon,
  AssuredWorkload as TaxIcon,
  AccountBalanceWallet as AccountIcon,
} from '@mui/icons-material';

import {
  AccountModel,
  FileResType,
  getAccountById,
  getPageFiles,
  TransactionModel,
  getTransactions,
} from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import {
  formatCurrency,
  formatCurrencyShortHand,
} from 'utils/CurrencyFormatter';
import { calculateRangeProgress } from 'utils/ProgressCalculator';
import AlertPopup, { AlertType } from 'components/AlertPopup/AlertPopup';
import { Copyright } from 'components/atoms';
import FilePreview from 'components/FilePreview/FilePreview';
import RemindersListCard from 'pages/Reminders/RemindersListCard';
import TransactionsListCard from 'pages/Transactions/TransactionsListCard';
import TransactionsBarChartCard from 'pages/Transactions/TransactionsBarChartCard';
import _ from 'lodash';
import { CurrentDateTimeUnix, CurrentMonthUnix } from 'utils/EpochConverter';
import TransactionsPieChartCard from 'pages/Transactions/TransactionsPieChartCard';
import DurationFilter from 'components/common/DurationFilter';

const AccountDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoaderState, getLoaderState } = useLoader();
  const { branch, employee, logout } = useAuth();

  const accountId = useParams().id || '';

  const [startDate, setStartDate] = useState(CurrentMonthUnix());
  const [endDate, setEndDate] = useState(CurrentDateTimeUnix());
  const [account, setAccount] = useState<AccountModel>();
  const [transactions, setTransactions] = useState<TransactionModel[]>([]);
  const [apiFiles, setApiFiles] = useState<FileResType[]>([]);

  const [alertType, setAlertType] = useState<AlertType>('info');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (accountId) {
      setLoaderState('accountDetails', true);
      setAccount(undefined);
      Promise.all([
        getAccountById(accountId),
        getTransactions({
          branchId: branch?.id,
          pageSize: 10000,
          filter: `accountId:is:${accountId};createdAt:between:${startDate},${endDate}`,
        }),
        getPageFiles('accounts', parseInt(accountId)),
      ])
        .then(results => {
          setAccount(results[0]);
          setTransactions(results[1].rows);
          setApiFiles(results[2]);
        })
        .catch(error => {
          if (error.response.status == 404) {
            // Triggers catch-all route.
            navigate('/notFound');
            return;
          }
          setAlertType('error');
          setAlertMessage(error?.message);
          setOpenAlertPopup(true);
        })
        .finally(() => setLoaderState('accountDetails', false));
    }
  }, [startDate, endDate]);

  const handleAlertPopupClose = () => {
    setOpenAlertPopup(false);
  };

  return (
    <>
      <Helmet>
        <title>{account ? 'SKC - ' + account.name : 'SKC'}</title>
      </Helmet>

      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}>
              {account?.name || 'SKC'}
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{employee?.name.charAt(0).toUpperCase()}</Avatar>
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="projectDetail-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                <MenuItem sx={{ display: 'block', width: '250px' }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{ textOverflow: 'ellipsis' }}>
                        {employee?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} pt={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ height: '1.6em' }}>
                        {employee?.position}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      pt={1}
                      sx={{ alignContent: 'center', textAlign: 'right' }}>
                      <Typography variant="body2" color="grey">
                        {branch?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setLoaderState('global', true);
                    logout().finally(() => {
                      setLoaderState('global', false);
                      navigate('/');
                    });
                  }}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Grid container sx={{ mt: 6 }} spacing={2} p={2}>
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={{ p: 2 }}>
              <Grid container>
                <Grid item xs={10} pb={1}>
                  <Typography variant="h5" fontWeight={700}>
                    Details
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" pb={1}>
                  <Icon fontSize="large">
                    <InfoIcon fontSize="large" />
                  </Icon>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {account ? account.name : <Skeleton animation="wave" />}
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Code
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {account ? account.code : <Skeleton animation="wave" />}
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    No
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {account ? account.details : <Skeleton animation="wave" />}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={{ p: 2 }}>
              <Grid container>
                <Grid item xs={10} pb={1}>
                  <Typography variant="h5" fontWeight={700}>
                    Account
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" pb={1}>
                  <Icon fontSize="large">
                    <AccountIcon fontSize="large" />
                  </Icon>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Balance
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {account ? (
                      formatCurrency(account.balance)
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    noWrap
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Minimum
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {account ? (
                      formatCurrency(account.minimumBalance)
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Usage
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25} alignContent="center">
                  {account ? (
                    <LinearProgress
                      sx={{ height: 0.5 }}
                      variant="determinate"
                      value={calculateRangeProgress(
                        account.balance,
                        0,
                        account.minimumBalance,
                      )}
                    />
                  ) : (
                    <Skeleton />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={{ p: 2 }}>
              <Grid container>
                <Grid item xs={10} pb={1}>
                  <Typography variant="h5" fontWeight={700}>
                    Tax
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" pb={1}>
                  <Icon fontSize="large">
                    <TaxIcon fontSize="large" />
                  </Icon>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Total
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {account ? (
                      formatCurrency(account.gst)
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} pt={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    CGST
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="center" pt={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    SGST
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right" pt={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    IGST
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography noWrap variant="h6" fontWeight={700}>
                    {getLoaderState('accountDetails') ? (
                      <Skeleton animation="wave" width={50} />
                    ) : (
                      formatCurrencyShortHand(account?.cGst)
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} justifyItems="center">
                  <Typography noWrap variant="h6" fontWeight={700}>
                    {getLoaderState('accountDetails') ? (
                      <Skeleton animation="wave" width={50} />
                    ) : (
                      formatCurrencyShortHand(account?.sGst)
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} justifyItems="right">
                  <Typography noWrap variant="h6" fontWeight={700}>
                    {getLoaderState('accountDetails') ? (
                      <Skeleton animation="wave" width={50} />
                    ) : (
                      formatCurrencyShortHand(account?.iGst)
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={{ p: 2 }}>
              <Grid container>
                <Grid item xs={10} pb={1}>
                  <Typography variant="h5" fontWeight={700}>
                    Summary
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" pb={1}>
                  <Icon fontSize="large">
                    <SummaryIcon fontSize="large" />
                  </Icon>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Total
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {getLoaderState('accountDetails') ? (
                      <Skeleton animation="wave" />
                    ) : (
                      transactions.length
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} pt={0.25}>
                  <Typography
                    noWrap
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Credit
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="center" pt={0.25}>
                  <Typography
                    noWrap
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Debit
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right" pt={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Pending
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" fontWeight={700}>
                    {getLoaderState('accountDetails') ? (
                      <Skeleton animation="wave" width={50} />
                    ) : (
                      formatCurrencyShortHand(
                        _.sumBy(
                          _.filter(transactions, {
                            transactionType: 'Credit',
                            transactionStatus: 'Completed',
                          }),
                          item => Number(item.amount),
                        ),
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} justifyItems="center">
                  <Typography variant="h6" fontWeight={700}>
                    {getLoaderState('accountDetails') ? (
                      <Skeleton animation="wave" width={50} />
                    ) : (
                      formatCurrencyShortHand(
                        _.sumBy(
                          _.filter(transactions, {
                            transactionType: 'Debit',
                            transactionStatus: 'Completed',
                          }),
                          item => Number(item.amount),
                        ),
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} justifyItems="right">
                  <Typography variant="h6" fontWeight={700}>
                    {getLoaderState('accountDetails') ? (
                      <Skeleton animation="wave" width={50} />
                    ) : (
                      formatCurrencyShortHand(
                        _.sumBy(
                          _.filter(transactions, {
                            transactionStatus: 'Pending',
                          }),
                          item => Number(item.amount),
                        ),
                      )
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TransactionsBarChartCard
              filter={`accountId:is:${accountId};createdAt:between:${startDate},${endDate}`}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TransactionsPieChartCard
              filter={`accountId:is:${accountId};createdAt:between:${startDate},${endDate}`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TransactionsListCard
              filter={`accountId:is:${accountId};createdAt:between:${startDate},${endDate}`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RemindersListCard filter={`accountId:is:${accountId}`} />
          </Grid>
          <Grid item xs={12}>
            <FilePreview files={apiFiles} />
          </Grid>
          <Grid item xs={12}>
            <Copyright />
          </Grid>
        </Grid>
      </Box>

      <DurationFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      {alertMessage && (
        <AlertPopup
          type={alertType}
          open={openAlertPopup}
          message={alertMessage}
          onClose={handleAlertPopupClose}
          autoHideDuration={3000}
        />
      )}
    </>
  );
};

export default React.memo(AccountDetail);
