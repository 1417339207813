import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { GridColDef, GridRowId } from '@mui/x-data-grid';

import {
  AccountModel,
  createAccount,
  deleteAccount,
  getAccounts,
  updateAccount,
} from 'apis';
import { useLoader } from 'contexts/LoaderContext';
import AccountForm from './AccountForm';
import { useAuth } from 'contexts/AuthContext';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { PageId } from 'constants/PageId';
import TableActions from 'components/TableGrid/TableActions';
import TableGrid from 'components/TableGrid/TableGrid';

export type FormModeType = 'create' | 'edit' | 'delete';

const Accounts = () => {
  const { branch, hasPermission } = useAuth();
  const { setLoaderState } = useLoader();

  const [selectedAccountID, setSelectedAccountID] = useState<GridRowId>();
  const [accountsData, setAccountsData] = useState<AccountModel[]>([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const handleAddAccount = (newAccount: Partial<AccountModel>) => {
    setLoaderState('accountsForm', true);
    createAccount(newAccount as Omit<AccountModel, 'id'>)
      .then(response => {
        setAccountsData(prevAccounts => [...prevAccounts, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding account: ', error))
      .finally(() => setLoaderState('accountsForm', false));
  };

  const handleEditAccount = (editedAccount: Partial<AccountModel>) => {
    setLoaderState('accountsForm', true);
    if (selectedAccountID) {
      updateAccount(selectedAccountID, editedAccount)
        .then(response => {
          setAccountsData(
            _.chain(accountsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(accounts => {
                const index = _.findIndex(
                  accounts,
                  acc => acc.id === selectedAccountID,
                );
                if (index !== -1) accounts[index] = response;
                return accounts;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing account: ', error))
        .finally(() => setLoaderState('accountsForm', false));
    }
  };

  const handleDeleteAccount = () => {
    if (selectedAccountID) {
      setLoaderState('accountsForm', true);
      deleteAccount(selectedAccountID)
        .then(() => {
          setAccountsData(
            _.reject(accountsData, {
              id: selectedAccountID,
            }) as AccountModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting account: ', error))
        .finally(() => setLoaderState('accountsForm', false));
    }
  };

  const openAccountForm = () => {
    let title: string;
    let currentAccount: Partial<AccountModel> = {
      branchId: branch?.id,
      name: '',
      code: '',
      balance: 0,
      minimumBalance: 0,
      gst: 0,
      iGst: 0,
      cGst: 0,
      sGst: 0,
      details: '',
      notes: '',
    };
    let callback: (account: Partial<AccountModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Account';
        callback = handleAddAccount;
        break;
      case 'edit':
        title = 'Edit Account';
        callback = handleEditAccount;
        currentAccount = _.chain(accountsData)
          .find(transaction => transaction.id === selectedAccountID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Account';
        callback = handleDeleteAccount;
        currentAccount = _.chain(accountsData)
          .find(transaction => transaction.id === selectedAccountID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Account';
        callback = handleAddAccount;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <AccountForm
            mode={formMode}
            initFormState={currentAccount}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleView = (id: GridRowId) => {
    // Securely open a new tab
    const newWindow = window.open(
      `/app/accounts/${id}`,
      '_blank',
      'noopener,noreferrer',
    );

    if (newWindow) {
      newWindow.opener = null; // Prevent reverse tabnabbing
    }
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedAccountID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedAccountID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.6,
      type: 'string',
    },
    { field: 'code', headerName: 'CODE', flex: 0.4, type: 'string' },
    {
      field: 'balance',
      headerName: 'BALANCE',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'minimumBalance',
      headerName: 'MINIMUM BALANCE',
      flex: 0.8,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'gst',
      headerName: 'GST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'cGst',
      headerName: 'CGST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'sGst',
      headerName: 'SGST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'iGst',
      headerName: 'IGST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    TableActions({
      pageId: PageId.Accounts,
      handleView: handleView,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Accounts</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <TableGrid
          pageId={PageId.Accounts}
          columns={columns}
          loaderName={'accountsTable'}
          fetchFunction={getAccounts}
          createCallback={handleOpen}
          rows={accountsData}
          setRows={(data: AccountModel[]) => {
            setAccountsData(data);
          }}
        />
      </Paper>

      {openForm && openAccountForm()}
    </>
  );
};

export default React.memo(Accounts);
