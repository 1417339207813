import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Paper } from '@mui/material';
import { grid } from 'ldrs';
import { motion, AnimatePresence } from 'framer-motion';

import { useAuth } from 'contexts/AuthContext';
import { PermissionType } from 'utils/PermissionsConfig';
import { PageId } from 'constants/PageId';
import PermissionGuard from 'components/common/PermissionGuard';

interface PrivateRouteProps {
  pageId?: PageId;
  permissions?: PermissionType[];
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  pageId,
  permissions,
  children,
}) => {
  const { isAuthenticated, authLoading } = useAuth();
  const location = useLocation();

  grid.register();

  return (
    <>
      {/* Check authentication status and display loader */}
      {authLoading && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>
            <Paper
              elevation={0}
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <l-grid size="100" speed="2" color="black"></l-grid>
            </Paper>
          </motion.div>
        </AnimatePresence>
      )}

      {/* Redirect to main page if not authenticated */}
      {!authLoading && !isAuthenticated && (
        <Navigate to="/login" state={{ from: location }} replace />
      )}

      {/* Redirect to no permissions page if not authorized */}
      {pageId && !authLoading && isAuthenticated && (
        <PermissionGuard
          pageId={pageId}
          permissionType={
            permissions
              ? permissions
              : [
                  PermissionType.Add,
                  PermissionType.View,
                  PermissionType.Modify,
                  PermissionType.Delete,
                ]
          }
          noPermissionComponent={
            <Navigate to="/noPermission" state={{ from: location }} replace />
          }
        />
      )}

      {/* Check authentication state and display content */}
      {!authLoading && isAuthenticated && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.5 }}>
            {children}
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default PrivateRoute;
