import React, { useState } from 'react';
import _ from 'lodash';

import {
  CircularProgress,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';

import { ConfirmDialog } from 'components';
import { FileResType } from 'apis';
import { AcceptMimeType } from 'utils/AcceptMimeType';
import { ShortenFileName } from 'utils/ShortenFileName';
import { useLoader } from 'contexts/LoaderContext';

interface FileUploadType {
  mode: string | undefined;
  disabled: boolean;
  localFiles: File[];
  apiFiles: FileResType[];
  handleFileChangeFn: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDeleteFn: (fileToDelete: File | FileResType) => void;
}

const FileUpload = (props: FileUploadType) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<File | FileResType>();

  const { getLoaderState, getLoaderProgress } = useLoader();

  const triggerConfirmDialog = (file: File | FileResType) => {
    setFileToDelete(file);
    setOpenConfirmDialog(true);
  };

  const onConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const onConfirmDialogConfirm = () => {
    setOpenConfirmDialog(false);
    if (fileToDelete) {
      props.handleFileDeleteFn(fileToDelete);
    }
  };

  const handleOpenFile = (file: FileResType) => {
    if (file.url) {
      const newWindow = window.open(file.url, '_blank');
      if (newWindow) {
        newWindow.opener = null; // Enhanced security
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8} pt={2}>
          <Typography
            variant="h5"
            fontWeight={700}
            color={props.disabled ? 'text.disabled' : 'text.primary'}>
            Attachments
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="right" pt={2}>
          <label htmlFor="file-upload">
            <Input
              id="file-upload"
              type="file"
              disabled={props.disabled || getLoaderState('fileUpload')}
              inputProps={{
                accept: _.join(
                  [AcceptMimeType('image'), AcceptMimeType('pdf')],
                  ',',
                ),
                multiple: true,
              }}
              sx={{ display: 'none' }}
              onChange={props.handleFileChangeFn}
            />

            <LoadingButton
              variant="outlined"
              component="span"
              size="small"
              loading={getLoaderState('fileUpload')}
              disabled={props.disabled || getLoaderState('fileUpload')}
              startIcon={<CloudUploadIcon />}
              loadingIndicator={
                getLoaderProgress('fileUploadProgress') == 0 ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <Typography
                    variant="caption"
                    component="div"
                    sx={{
                      color: 'text.secondary',
                    }}>
                    {getLoaderProgress('fileUploadProgress') + '%'}
                  </Typography>
                )
              }>
              Upload
            </LoadingButton>
          </label>
        </Grid>
      </Grid>

      {/* TODO: Below logic needs improvement */}
      {props.mode === 'create' &&
        (props.localFiles.length > 0 ? (
          <>
            <List>
              {_.map(props.localFiles, (file, index) => (
                <ListItem key={index} sx={{ pl: 0 }} divider>
                  <ListItemText
                    primary={
                      <Typography>{ShortenFileName(file.name, 50)}</Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => triggerConfirmDialog(file)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <img
              style={{
                height: '10vh',
                margin: 'auto',
                display: 'flex',
                paddingTop: '1rem',
              }}
              src="/illustrations/addFiles.svg"
            />
            <Typography
              variant="body2"
              color="textSecondary"
              pt={2}
              pb={2}
              textAlign="center">
              No attachments selected.
            </Typography>
          </>
        ))}

      {(props.mode === 'edit' || props.mode === 'delete') &&
        (props.apiFiles.length > 0 ? (
          <>
            <List>
              {_.map(props.apiFiles, (file, index) => (
                <ListItem key={index} sx={{ pl: 0 }} divider>
                  <ListItemText
                    primary={
                      <Typography
                        color={
                          props.disabled ? 'textSecondary' : 'textPrimary'
                        }>
                        {ShortenFileName(file.name, 50)}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Stack direction="row" spacing={1}>
                      <Tooltip
                        title="Open File"
                        placement="left"
                        disableHoverListener={props.disabled}>
                        <span>
                          <IconButton
                            edge="end"
                            disabled={props.disabled}
                            onClick={() => handleOpenFile(file)}>
                            <OpenInNewIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip
                        title="Delete File"
                        disableHoverListener={props.disabled}>
                        <span>
                          <IconButton
                            edge="end"
                            color="error"
                            disabled={props.disabled}
                            onClick={() => triggerConfirmDialog(file)}>
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Stack>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <img
              style={{
                height: '10vh',
                margin: 'auto',
                display: 'flex',
                paddingTop: '1rem',
              }}
              src="/illustrations/addFiles.svg"
            />
            <Typography
              variant="body2"
              color="textSecondary"
              pt={2}
              pb={2}
              textAlign="center">
              No attachments found.
            </Typography>
          </>
        ))}

      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          title="Confirm Delete"
          content="Are you sure you want to delete the attachment?"
          onClose={onConfirmDialogClose}
          onConfirm={onConfirmDialogConfirm}
        />
      )}
    </>
  );
};

export default React.memo(FileUpload);
