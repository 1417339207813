import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Box, Card, Grid, Icon, Typography } from '@mui/material';
import { ReceiptLong as TransactionsIcon } from '@mui/icons-material';

import { getTransactions, TransactionModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import StackedChart from 'components/Charts/StackedChart';
import { DateMinFormat } from 'utils/EpochConverter';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';

interface TransactionsBarChartCardProps {
  filter: string;
  startDate: number;
  endDate: number;
}

const TransactionsBarChartCard = (props: TransactionsBarChartCardProps) => {
  const { branch, runWithPermission } = useAuth();

  const { getLoaderState, setLoaderState } = useLoader();

  const [transactions, setTransactions] = useState<TransactionModel[]>([]);

  const [dateList, setDateList] = useState<string[]>([]);
  const [creditAmountData, setCreditAmountData] = useState<number[]>([]);
  const [debitAmountData, setDebitAmountData] = useState<number[]>([]);

  useEffect(() => {
    if (transactions.length == 0) {
      return;
    }
    const dates: string[] = [];
    const creditData: number[] = [];
    const debitData: number[] = [];
    const dateStarts = _.range(props.startDate, props.endDate, 86400);
    _.map(dateStarts, (value: number) => {
      const creditTotal = _.sumBy(
        _.filter(
          transactions,
          item =>
            item.transactionType == 'Credit' &&
            item.createdAt >= value &&
            item.createdAt < value + 86400,
        ),
        item => Number(item.amount),
      );
      const debitTotal = _.sumBy(
        _.filter(
          transactions,
          item =>
            item.transactionType == 'Debit' &&
            item.createdAt >= value &&
            item.createdAt < value + 86400,
        ),
        item => Number(item.amount),
      );
      dates.push(DateMinFormat(value));
      creditData.push(creditTotal);
      debitData.push(debitTotal);
    });
    setDateList(dates);
    setCreditAmountData(creditData);
    setDebitAmountData(debitData);
  }, [transactions]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('transactionsBarChartCard', true);
        Promise.all([
          getTransactions({
            branchId: branch?.id,
            pageSize: 10000,
            filter: props.filter,
          }),
        ])
          .then(results => {
            setTransactions(results[0].rows);
          })
          .finally(() => setLoaderState('transactionsBarChartCard', false));
      },
      PageId.Transactions,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card sx={{ p: { xs: 2, lg: 3 }, pb: 1 }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Transactions
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <TransactionsIcon fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={12} height={400} pb={3}>
          {!getLoaderState('transactionsBarChartCard') &&
          transactions.length == 0 ? (
            <Box
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <PermissionGuard
                pageId={PageId.Transactions}
                permissionType={PermissionType.View}
                noPermissionComponent={
                  <>
                    <img
                      style={{
                        width: '8vw',
                        minWidth: '100px',
                      }}
                      src="/illustrations/noPermission.svg"
                    />
                    <Typography p={3} color="grey">
                      No Permission
                    </Typography>
                  </>
                }>
                <img
                  style={{
                    width: '8vw',
                    minWidth: '100px',
                  }}
                  src="/illustrations/noTransactions.svg"
                />
                <Typography p={3} color="grey">
                  No Transactions
                </Typography>
              </PermissionGuard>
            </Box>
          ) : (
            <StackedChart
              loaderName="transactionsBarChartCard"
              series1Name="Credit"
              series1={creditAmountData}
              series2Name="Debit"
              series2={debitAmountData}
              xAxis={dateList}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(TransactionsBarChartCard);
