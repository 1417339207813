import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedReminderModel {
  rows: ReminderModel[];
  total: number;
}

export interface ReminderModel {
  id: number;
  branchId: number;
  accountId: number;
  employeeId: number;
  projectId: number;
  customerId: number;
  supplierId: number;
  vehicleId: number;
  inventoryId: number;
  name: string;
  notes: string;
  time: number;
  status: string;
  'files[]'?: File[];
  createdAt: number;
  updatedAt: number;
}

export const getReminders = async (
  paramObj: object = {},
): Promise<PaginatedReminderModel> => {
  const response = await axios.get<PaginatedReminderModel>(`/reminders`, {
    params: paramObj,
  });
  return response.data;
};

export const getReminderById = async (id: number): Promise<ReminderModel> => {
  const response = await axios.get<ReminderModel>(`/reminders/${id}`);
  return response.data;
};

export const createReminder = async (
  reminderData: Omit<ReminderModel, 'id'>,
): Promise<ReminderModel> => {
  const response = await axios.post<ReminderModel>(`/reminders`, reminderData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateReminder = async (
  id: GridRowId,
  reminderData: Partial<ReminderModel>,
): Promise<ReminderModel> => {
  const response = await axios.put<ReminderModel>(
    `/reminders/${id}`,
    reminderData,
  );
  return response.data;
};

export const deleteReminder = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/reminders/${id}`);
};
