import React, { useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';
import { DateFormatString } from 'constants/DateTimeFormat';
import { DatePicker } from '@mui/x-date-pickers';
import {
  CurrentDateEndUnix,
  CurrentDateStartUnix,
  CurrentDateTimeUnix,
  CurrentMonthUnix,
  DateMinFormat,
  PreviousNMonthUnix,
} from 'utils/EpochConverter';

interface DurationFilterProps {
  startDate: number;
  setStartDate: (start: number) => void;
  endDate: number;
  setEndDate: (end: number) => void;
}

const DurationFilter = (props: DurationFilterProps) => {
  const [showDialog, setShowDialog] = useState(false);

  const [start, setStart] = useState(moment.unix(props.startDate));
  const [end, setEnd] = useState(moment.unix(props.endDate));

  return (
    <>
      {showDialog && (
        <Dialog
          open={showDialog}
          maxWidth={'xs'}
          onClose={() => setShowDialog(false)}>
          <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
            Time Range
          </Typography>
          <DialogContent sx={{ pt: 0 }}>
            <Box>
              <Grid container columnSpacing={3}>
                <Grid item xs={6} pt={3} pb={1}>
                  <DatePicker
                    label="Start"
                    value={start}
                    maxDate={moment.unix(CurrentDateTimeUnix())}
                    onChange={moment => (moment ? setStart(moment) : null)}
                    format={DateFormatString}
                  />
                </Grid>
                <Grid item xs={6} pt={3} pb={1}>
                  <DatePicker
                    label="End"
                    value={end}
                    maxDate={moment.unix(CurrentDateTimeUnix())}
                    onChange={moment => (moment ? setEnd(moment) : null)}
                    format={DateFormatString}
                  />
                </Grid>
                <Grid item xs={12} py={3}>
                  <ButtonGroup variant="outlined" fullWidth>
                    <Button
                      onClick={() => {
                        props.setStartDate(CurrentMonthUnix());
                        props.setEndDate(CurrentDateEndUnix(moment()));
                        setShowDialog(false);
                      }}>
                      1M
                    </Button>
                    <Button
                      onClick={() => {
                        props.setStartDate(PreviousNMonthUnix(3));
                        props.setEndDate(CurrentDateEndUnix(moment()));
                        setShowDialog(false);
                      }}>
                      3M
                    </Button>
                    <Button
                      onClick={() => {
                        props.setStartDate(PreviousNMonthUnix(6));
                        props.setEndDate(CurrentDateEndUnix(moment()));
                        setShowDialog(false);
                      }}>
                      6M
                    </Button>
                    <Button
                      onClick={() => {
                        props.setStartDate(PreviousNMonthUnix(12));
                        props.setEndDate(CurrentDateEndUnix(moment()));
                        setShowDialog(false);
                      }}>
                      1Y
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12} textAlign={'right'} pt={1}>
                  <Button
                    onClick={() => setShowDialog(false)}
                    variant="text"
                    sx={{ mr: 4 }}>
                    {'Close'}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ px: 6 }}
                    onClick={() => {
                      props.setStartDate(CurrentDateStartUnix(start));
                      props.setEndDate(CurrentDateEndUnix(end));
                      setShowDialog(false);
                    }}>
                    Select
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      <Fab
        color="primary"
        variant="extended"
        sx={{ position: 'fixed', bottom: '1rem', right: '1rem' }}
        onClick={() => setShowDialog(true)}>
        <DateRangeIcon sx={{ mr: 1 }} />
        {DateMinFormat(props.startDate) + ' - ' + DateMinFormat(props.endDate)}
      </Fab>
    </>
  );
};

export default React.memo(DurationFilter);
