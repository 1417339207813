import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Box, Card, Grid, Icon, Typography } from '@mui/material';
import { Category } from '@mui/icons-material';

import { getTransactions, TransactionModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { CircularChart } from 'components';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';

interface TransactionsPieChartCardProps {
  filter: string;
}

const TransactionsPieChartCard = (props: TransactionsPieChartCardProps) => {
  const { branch, runWithPermission } = useAuth();

  const { getLoaderState, setLoaderState } = useLoader();

  const [transactions, setTransactions] = useState<TransactionModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('transactionsPieChartCard', true);
        Promise.all([
          getTransactions({
            branchId: branch?.id,
            pageSize: 10000,
            filter: props.filter,
          }),
        ])
          .then(results => {
            setTransactions(results[0].rows);
          })
          .finally(() => setLoaderState('transactionsPieChartCard', false));
      },
      PageId.Transactions,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card sx={{ p: { xs: 2, lg: 3 }, pb: 1 }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Expenses
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <Category fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={12} height={400} pb={3}>
          {!getLoaderState('transactionsPieChartCard') &&
          transactions.length == 0 ? (
            <Box
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <PermissionGuard
                pageId={PageId.Transactions}
                permissionType={PermissionType.View}
                noPermissionComponent={
                  <>
                    <img
                      style={{
                        width: '8vw',
                        minWidth: '100px',
                      }}
                      src="/illustrations/noPermission.svg"
                    />
                    <Typography p={3} color="grey">
                      No Permission
                    </Typography>
                  </>
                }>
                <img
                  style={{
                    width: '8vw',
                    minWidth: '100px',
                  }}
                  src="/illustrations/noExpenses.svg"
                />
                <Typography p={3} color="grey">
                  No Expenses
                </Typography>
              </PermissionGuard>
            </Box>
          ) : (
            <CircularChart
              loaderName={'transactionsPieChartCard'}
              data={_.map(
                _.groupBy(transactions, 'category'),
                (items, category) => ({
                  label: category,
                  value: _.sumBy(items, item => Number(item.amount)),
                }),
              )}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(TransactionsPieChartCard);
