import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import { Sell as CreditIcon } from '@mui/icons-material';

import { getTransactions, TransactionModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';
import { DateFormat } from 'utils/EpochConverter';

interface CreditSummaryCardProps {
  filter: string;
  startDate: number;
}

const CreditSummaryCard = (props: CreditSummaryCardProps) => {
  const { branch, runWithPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [transactions, setTransactions] = useState<TransactionModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('creditSummaryCard', true);
        Promise.all([
          getTransactions({
            branchId: branch?.id,
            pageSize: 10000,
            fields: 'id,transactionType,category,amount,createdAt',
            filter: props.filter,
          }),
        ])
          .then(results => {
            setTransactions(results[0].rows);
          })
          .finally(() => setLoaderState('creditSummaryCard', false));
      },
      PageId.Transactions,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" pb={0} fontWeight={700}>
            Total Credit
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <CreditIcon fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" pb={1} color="secondary.light">
            {getLoaderState('creditSummaryCard') ? (
              <Skeleton animation="wave" width={200} />
            ) : (
              DateFormat(props.startDate) + ' - Now'
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700}>
            {getLoaderState('creditSummaryCard') ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <PermissionGuard
                pageId={PageId.Transactions}
                permissionType={PermissionType.View}
                noPermissionComponent={<>...</>}>
                {formatCurrency(
                  _.sumBy(
                    _.filter(transactions, { transactionType: 'Credit' }),
                    item => Number(item.amount),
                  ),
                )}
              </PermissionGuard>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(CreditSummaryCard);
