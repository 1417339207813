/**
 * Permission Code format to be followed (CRUD)
 *
 * This Code is to be XY where
 *
 * X: Page ID
 * Y: Permission Type (Add - 1, View - 2, Modify - 3, Delete - 4)
 *
 * Example if 'Page ID' is 123, Permission Code would be:
 *    Add: 1231
 *    View: 1232
 *    Modify: 1233
 *    Delete: 1234
 */

export enum PermissionType {
  Add = 1,
  View = 2,
  Modify = 3,
  Delete = 4,
}

export const generatePermissionCode = (
  pageId: number,
  type: PermissionType,
) => {
  return parseInt(`${pageId}${type}`);
};
