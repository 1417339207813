import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import _ from 'lodash';
import { motion } from 'framer-motion';

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AvailablePages, PageType } from 'constants/AvailablePages';
import PermissionGuard from 'components/common/PermissionGuard';
import { PermissionType } from 'utils/PermissionsConfig';

interface DrawerListType {
  navHeaderCallBackFn: (title: string) => void;
}

const DrawerList = (props: DrawerListType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPageId, setSelectedPageId] = useState(0);

  const handleListItemClick = (page: PageType) => {
    setSelectedPageId(page.id);
    props.navHeaderCallBackFn(page.title);
    navigate(page.url);
  };

  useEffect(() => {
    _.map(AvailablePages, page => {
      if (location.pathname.includes(page.url)) {
        setSelectedPageId(page.id);
        props.navHeaderCallBackFn(page.title);
      }
    });
  }, [location.pathname, props]);

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <List component="nav">
      {_.map(
        AvailablePages,
        page =>
          page.enabled && (
            <PermissionGuard
              key={page.id}
              pageId={page.id}
              permissionType={[
                PermissionType.Add,
                PermissionType.View,
                PermissionType.Modify,
                PermissionType.Delete,
              ]}>
              <ListItemButton
                selected={selectedPageId === page.id}
                onClick={() => handleListItemClick(page)}>
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}>
                  <ListItemIcon sx={{ pl: largeScreen ? 1 : 0 }}>
                    <Tooltip title={page.title} placement="right" arrow>
                      {<page.icon />}
                    </Tooltip>
                  </ListItemIcon>
                </motion.div>
                <ListItemText primary={page.title} />
              </ListItemButton>
            </PermissionGuard>
          ),
      )}
    </List>
  );
};

export default React.memo(DrawerList);
