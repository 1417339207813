import React from 'react';
import { PageId } from 'constants/PageId';
import PermissionGuard from 'components/common/PermissionGuard';
import { PermissionType } from 'utils/PermissionsConfig';
import { Box, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { FormModeType } from 'pages/Accounts/Accounts';

interface TableToolBarProps {
  pageId: PageId;
  createCallback: (mode: FormModeType) => void;
}

const TableToolBar = (props: TableToolBarProps) => {
  return (
    <GridToolbarContainer sx={{ pb: 1 }}>
      <PermissionGuard
        pageId={props.pageId}
        permissionType={PermissionType.Add}>
        <Button
          variant="contained"
          size="small"
          onClick={() => props.createCallback('create')}
          startIcon={<AddIcon />}>
          Add
        </Button>
      </PermissionGuard>

      <Box sx={{ flexGrow: 1 }} />

      <PermissionGuard
        pageId={props.pageId}
        permissionType={PermissionType.View}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </PermissionGuard>
    </GridToolbarContainer>
  );
};

export default React.memo(TableToolBar);
