import { ComponentType } from 'react';

import {
  Dashboard as DashboardIcon,
  AccountBalance as AccountIcon,
  Engineering as EmployeeIcon,
  CurrencyRupee as TransactionIcon,
  Groups as CustomerIcon,
  Inventory as InventoryIcon,
  Class as ProjectIcon,
  LocalShipping as VehicleIcon,
  NotificationsActive as ReminderIcon,
  PointOfSale as OperationIcon,
  Store as SupplierIcon,
  Today as TimeSheetIcon,
  Handyman as WorkLogIcon,
} from '@mui/icons-material';

import {
  Accounts,
  Customers,
  Dashboard,
  Employees,
  Inventories,
  Operations,
  Projects,
  Reminders,
  Suppliers,
  TimeSheets,
  Transactions,
  Vehicles,
  WorkLogs,
} from 'pages';
import { PageId } from './PageId';
import {
  generatePermissionCode,
  PermissionType,
} from 'utils/PermissionsConfig';

export interface PageType {
  id: PageId;
  title: string;
  url: string;
  icon: ComponentType;
  component: ComponentType;
  enabled: boolean;
  permissions: {
    [key: string]: number;
  };
}

const setPermissions = (PageId: number) => {
  return {
    Add: generatePermissionCode(PageId, PermissionType.Add),
    View: generatePermissionCode(PageId, PermissionType.View),
    Modify: generatePermissionCode(PageId, PermissionType.Modify),
    Delete: generatePermissionCode(PageId, PermissionType.Delete),
  };
};

export const AvailablePages: PageType[] = [
  {
    id: PageId.Dashboard,
    title: 'Dashboard',
    url: 'dashboard',
    icon: DashboardIcon,
    component: Dashboard,
    enabled: true,
    permissions: {
      View: generatePermissionCode(PageId.Dashboard, PermissionType.View),
    },
  },
  {
    id: PageId.Projects,
    title: 'Projects',
    url: 'projects',
    icon: ProjectIcon,
    component: Projects,
    enabled: true,
    permissions: setPermissions(PageId.Projects),
  },
  {
    id: PageId.WorkLogs,
    title: 'Work Logs',
    url: 'worklogs',
    icon: WorkLogIcon,
    component: WorkLogs,
    enabled: true,
    permissions: setPermissions(PageId.WorkLogs),
  },
  {
    id: PageId.Transactions,
    title: 'Transactions',
    url: 'transactions',
    icon: TransactionIcon,
    component: Transactions,
    enabled: true,
    permissions: setPermissions(PageId.Transactions),
  },
  {
    id: PageId.Customers,
    title: 'Customers',
    url: 'customers',
    icon: CustomerIcon,
    component: Customers,
    enabled: true,
    permissions: setPermissions(PageId.Customers),
  },
  {
    id: PageId.Suppliers,
    title: 'Suppliers',
    url: 'suppliers',
    icon: SupplierIcon,
    component: Suppliers,
    enabled: true,
    permissions: setPermissions(PageId.Suppliers),
  },
  {
    id: PageId.Inventories,
    title: 'Inventory',
    url: 'inventory',
    icon: InventoryIcon,
    component: Inventories,
    enabled: true,
    permissions: setPermissions(PageId.Inventories),
  },
  {
    id: PageId.Operations,
    title: 'Operations',
    url: 'operations',
    icon: OperationIcon,
    component: Operations,
    enabled: true,
    permissions: setPermissions(PageId.Operations),
  },
  {
    id: PageId.TimeSheets,
    title: 'Time Sheets',
    url: 'timesheets',
    icon: TimeSheetIcon,
    component: TimeSheets,
    enabled: true,
    permissions: setPermissions(PageId.TimeSheets),
  },
  {
    id: PageId.Reminders,
    title: 'Reminders',
    url: 'reminders',
    icon: ReminderIcon,
    component: Reminders,
    enabled: true,
    permissions: setPermissions(PageId.Reminders),
  },
  {
    id: PageId.Accounts,
    title: 'Accounts',
    url: 'accounts',
    icon: AccountIcon,
    component: Accounts,
    enabled: true,
    permissions: setPermissions(PageId.Accounts),
  },
  {
    id: PageId.Vehicles,
    title: 'Vehicles',
    url: 'vehicles',
    icon: VehicleIcon,
    component: Vehicles,
    enabled: true,
    permissions: setPermissions(PageId.Vehicles),
  },
  {
    id: PageId.Employees,
    title: 'Employees',
    url: 'employees',
    icon: EmployeeIcon,
    component: Employees,
    enabled: true,
    permissions: setPermissions(PageId.Employees),
  },
];
