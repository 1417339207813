import React, { useEffect, useState } from 'react';

import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  CircularProgress,
  Divider,
  IconButton,
  ListItemSecondaryAction,
  Card,
  Icon,
} from '@mui/material';

import {
  PictureAsPdf as PictureAsPdfIcon,
  Image as ImageIcon,
  Attachment as AttachmentIcon,
  CloudDownload as CloudDownloadIcon,
  Folder as FilesIcon,
} from '@mui/icons-material';

import { FileResType } from 'apis';
import { ShortenFileName } from 'utils/ShortenFileName';

const loadImage = (src: string) => {
  return new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve();
    img.onerror = err => reject(err);
  });
};

interface FilePreviewProps {
  files: FileResType[];
}

const FilePreview: React.FC<FilePreviewProps> = ({ files }) => {
  const [selectedFile, setSelectedFile] = useState<FileResType | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      const fileType = selectedFile.name.split('.').pop();

      setLoading(true);

      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        loadImage(selectedFile.url)
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      } else if (fileType === 'pdf') {
        setLoading(true);
        setTimeout(() => setLoading(false), 1000); // Mock PDF load time
      } else {
        setLoading(false);
      }
    }
  }, [selectedFile]);

  const handleClick = (file: FileResType) => {
    setSelectedFile(file);
  };

  const renderIcon = (fileName: string) => {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension || '')) {
      return <ImageIcon />;
    } else if (fileExtension === 'pdf') {
      return <PictureAsPdfIcon />;
    } else {
      return <AttachmentIcon />;
    }
  };

  const renderPreview = () => {
    if (!selectedFile) return null;

    const { name, url } = selectedFile;
    const fileType = name.split('.').pop();

    if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
            {selectedFile.name}
          </Typography>
          <img
            src={url}
            alt={name}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Box>
      );
    } else if (fileType === 'pdf') {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
            {selectedFile.name}
          </Typography>
          <embed src={url} type="application/pdf" width="100%" height="600px" />
        </Box>
      );
    } else {
      return (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <img
            style={{
              width: '8vw',
              minWidth: '100px',
            }}
            src="/illustrations/viewFiles.svg"
          />
          <Typography p={3} color="grey">
            Preview not supported
          </Typography>
        </Box>
      );
    }
  };

  const handleDownload = async (url: string) => {
    // TODO: Download not working from google API - issue #58
    // try {
    //   const response = await fetch(url, {
    //     method: 'GET',
    //     mode: 'cors',
    //   });
    //   const blob = await response.blob();
    //   const downloadUrl = window.URL.createObjectURL(blob);
    //   const link = document.createElement('a');
    //   link.href = downloadUrl;
    //   link.setAttribute('download', filename); // Specify the filename
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   window.URL.revokeObjectURL(downloadUrl); // Clean up
    // } catch (error) {
    //   console.error('Error downloading file:', error);
    // }
    if (url) {
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.opener = null; // Enhanced security
      }
    }
  };

  return (
    <Card
      sx={{
        p: 3,
        display: 'grid',
        alignContent: files.length == 0 ? 'stretch' : 'baseline',
      }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Attachments
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <FilesIcon fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={12} lg={files.length > 0 ? 4 : 0}>
          <List>
            {files.map(file => (
              <React.Fragment key={file.id}>
                <ListItem
                  button
                  onClick={() => handleClick(file)}
                  selected={selectedFile?.id === file.id}>
                  {renderIcon(file.name)}
                  &nbsp;
                  <ListItemText primary={ShortenFileName(file.name, 50)} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handleDownload(file.url)}>
                      <CloudDownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          {files.length == 0 ? (
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <img
                style={{
                  width: '8vw',
                  minWidth: '100px',
                }}
                src="/illustrations/noData.svg"
              />
              <Typography p={3} color="grey">
                No attachments found
              </Typography>
            </Box>
          ) : null}
        </Grid>
        <Grid
          item
          xs={0}
          lg={files.length > 0 ? 8 : 12}
          sx={{ display: { xs: 'none', lg: 'block' } }}>
          {selectedFile ? (
            <Box height="100%">
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="400px">
                  <CircularProgress />
                </Box>
              ) : (
                renderPreview()
              )}
            </Box>
          ) : files.length > 0 ? (
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <img
                style={{
                  width: '8vw',
                  minWidth: '100px',
                }}
                src="/illustrations/viewFiles.svg"
              />
              <Typography pt={3} color="grey">
                Select a file to preview
              </Typography>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(FilePreview);
