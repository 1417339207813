import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import { Construction } from '@mui/icons-material';

import { getProjects, ProjectModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';

interface ProjectsSummaryCardProps {
  filter: string;
}

const ProjectsSummaryCard = (props: ProjectsSummaryCardProps) => {
  const { branch, runWithPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [projects, setProjects] = useState<ProjectModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('projectsSummaryCard', true);
        Promise.all([
          getProjects({
            branchId: branch?.id,
            pageSize: 10000,
            fields: 'status',
            filter: props.filter,
          }),
        ])
          .then(results => {
            setProjects(results[0].rows);
          })
          .finally(() => setLoaderState('projectsSummaryCard', false));
      },
      PageId.Projects,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" pb={0} fontWeight={700}>
            Projects
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <Construction fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" pb={1} color="secondary.light">
            Active
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography variant="subtitle1" pb={1} color="secondary.light">
            Paused
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="right">
          <Typography variant="subtitle1" pb={1} color="secondary.light">
            Complete
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" fontWeight={700}>
            {getLoaderState('projectsSummaryCard') ? (
              <Skeleton animation="wave" width={50} />
            ) : (
              <PermissionGuard
                pageId={PageId.Projects}
                permissionType={PermissionType.View}
                noPermissionComponent={<>...</>}>
                {_.size(
                  _.filter(
                    projects,
                    item => !['Work Paused', 'Completed'].includes(item.status),
                  ),
                )}
              </PermissionGuard>
            )}
          </Typography>
        </Grid>
        <Grid item xs={4} justifyItems="center">
          <Typography variant="h6" fontWeight={700}>
            {getLoaderState('projectsSummaryCard') ? (
              <Skeleton animation="wave" width={50} />
            ) : (
              <PermissionGuard
                pageId={PageId.Projects}
                permissionType={PermissionType.View}
                noPermissionComponent={<>...</>}>
                {_.size(
                  _.filter(projects, item =>
                    ['Work Paused'].includes(item.status),
                  ),
                )}
              </PermissionGuard>
            )}
          </Typography>
        </Grid>
        <Grid item xs={4} justifyItems="right">
          <Typography variant="h6" fontWeight={700}>
            {getLoaderState('projectsSummaryCard') ? (
              <Skeleton animation="wave" width={50} />
            ) : (
              <PermissionGuard
                pageId={PageId.Projects}
                permissionType={PermissionType.View}
                noPermissionComponent={<>...</>}>
                {_.size(
                  _.filter(projects, item =>
                    ['Completed'].includes(item.status),
                  ),
                )}
              </PermissionGuard>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(ProjectsSummaryCard);
