import React from 'react';

export const PhoneFieldProps = {
  inputProps: {
    maxLength: 10,
  },
};

export const PercentageFieldProps = {
  endAdornment: <pre>%</pre>,
  inputProps: {
    min: 0,
    step: 0.01,
  },
};

export const CurrencyFieldProps = {
  startAdornment: <pre>₹ </pre>,
  inputProps: {
    min: 0,
    step: 0.01,
  },
};

export const OdometerFieldProps = {
  startAdornment: <pre>KMS/HRS </pre>,
};

export const GstInFieldProps = {
  inputProps: {
    maxLength: 15,
    style: { textTransform: 'uppercase' as const },
  },
};

export const PANFieldProps = {
  inputProps: {
    maxLength: 10,
    style: { textTransform: 'uppercase' as const },
  },
};

export const LicenseFieldProps = {
  inputProps: {
    maxLength: 16,
    style: { textTransform: 'uppercase' as const },
  },
};

export const RegistrationFieldProps = {
  inputProps: {
    maxLength: 10,
    style: { textTransform: 'uppercase' as const },
  },
};

export const AadhaarFieldProps = {
  inputProps: {
    maxLength: 12,
  },
};
