import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import {
  createProject,
  deleteProject,
  getProjects,
  ProjectModel,
  updateProject,
} from 'apis/ProjectApi';
import { useLoader } from 'contexts/LoaderContext';
import ProjectForm from './ProjectForm';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { ProjectFormStatusOptions } from 'constants/DropDownOptions';
import { CurrentDateUnix } from 'utils/EpochConverter';
import { useAuth } from 'contexts/AuthContext';
import { CustomerModel, getCustomers } from 'apis/CustomerApi';
import NoResultsOverlay from 'components/TableGrid/NoResultsOverlay';
import NoRowsOverlay from 'components/TableGrid/NoRowsOverlay';
import { EMPTY_COLUMN } from 'constants/TableConstants';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import TableToolBar from 'components/TableGrid/TableToolBar';
import TableActions from 'components/TableGrid/TableActions';

export type FormModeType = 'create' | 'edit' | 'delete';

const Projects = () => {
  const { branch, runWithPermission, hasPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [selectedProjectID, setSelectedProjectID] = useState<GridRowId>();
  const [projectsData, setProjectsData] = useState<ProjectModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [customersList, setCustomersList] = useState<CustomerModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('projectsTable', true);
        getProjects({
          branchId: branch?.id,
          page: page + 1,
          pageSize: pageSize,
          sort: sortColumn + ':' + sortOrder,
          filter: filterField + ':' + filterOperator + ':' + filterValue,
        })
          .then(response => {
            setProjectsData(response.rows);
            setRowCount(response.total);
          })
          .catch(error => console.error('Error Fetching Projects: ', error))
          .finally(() => setLoaderState('projectsTable', false));
      },
      PageId.Projects,
      PermissionType.View,
    );
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    Promise.all([
      getCustomers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
    ]).then(results => {
      setCustomersList(results[0].rows);
    });
  }, []);

  const handleAddProject = (newProject: Partial<ProjectModel>) => {
    setLoaderState('projectsForm', true);
    createProject(newProject as Omit<ProjectModel, 'id'>)
      .then(response => {
        setProjectsData(prevProjects => [...prevProjects, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding project: ', error))
      .finally(() => setLoaderState('projectsForm', false));
  };

  const handleEditProject = (editedProject: Partial<ProjectModel>) => {
    setLoaderState('projectsForm', true);
    if (selectedProjectID) {
      updateProject(selectedProjectID, editedProject)
        .then(response => {
          setProjectsData(
            _.chain(projectsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(projects => {
                const index = _.findIndex(
                  projects,
                  proj => proj.id === selectedProjectID,
                );
                if (index !== -1) projects[index] = response;
                return projects;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing project: ', error))
        .finally(() => setLoaderState('projectsForm', false));
    }
  };

  const handleDeleteProject = () => {
    if (selectedProjectID) {
      setLoaderState('projectsForm', true);
      deleteProject(selectedProjectID)
        .then(() => {
          setProjectsData(
            _.reject(projectsData, {
              id: selectedProjectID,
            }) as ProjectModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting project: ', error))
        .finally(() => setLoaderState('projectsForm', false));
    }
  };

  const openProjectForm = () => {
    let title: string;
    let currentProject: Partial<ProjectModel> = {
      viewId: '',
      branchId: branch?.id,
      customerId: 0,
      name: '',
      location: '',
      status: '',
      startDate: CurrentDateUnix(),
      endDate: CurrentDateUnix(),
      totalBudget: 0,
      totalExpense: 0,
      notes: '',
    };
    let callback: (project: Partial<ProjectModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Project';
        callback = handleAddProject;
        break;
      case 'edit':
        callback = handleEditProject;
        currentProject = _.chain(projectsData)
          .find(project => project.id === selectedProjectID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        title = '' + currentProject.viewId;
        break;
      case 'delete':
        callback = handleDeleteProject;
        currentProject = _.chain(projectsData)
          .find(project => project.id === selectedProjectID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        title = '' + currentProject.viewId;
        break;
      default:
        title = 'New Project';
        callback = handleAddProject;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <ProjectForm
            mode={formMode}
            initFormState={currentProject}
            customersList={customersList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleView = (id: GridRowId) => {
    const project = _.find(projectsData, { id: id as number });
    // Securely open a new tab
    const newWindow = window.open(
      `/app/projects/${id}?customerId=${project?.customerId}`,
      '_blank',
      'noopener,noreferrer',
    );

    if (newWindow) {
      newWindow.opener = null; // Prevent reverse tabnabbing
    }
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedProjectID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedProjectID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    { field: 'viewId', headerName: 'ID', flex: 1, type: 'string' },
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      type: 'string',
    },
    {
      field: 'customerId',
      headerName: 'CLIENT',
      flex: 1,
      type: 'singleSelect',
      valueOptions: _.map(customersList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(customersList, record => {
          return record.id == value;
        });
        return result ? result.name : EMPTY_COLUMN;
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 1,
      type: 'singleSelect',
      valueOptions: _.map(ProjectFormStatusOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(ProjectFormStatusOptions, {
          value: value as string,
        });
        return result ? result.label : EMPTY_COLUMN;
      },
    },
    {
      field: 'totalBudget',
      headerName: 'BUDGET',
      flex: 0.8,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'totalExpense',
      headerName: 'EXPENSE',
      flex: 0.8,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    TableActions({
      pageId: PageId.Projects,
      handleView: handleView,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Projects</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <DataGrid
          loading={getLoaderState('projectsTable')}
          rows={projectsData}
          columns={_.compact(columns)}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: TableToolBar as unknown as JSXElementConstructor<
              GridToolbarProps & ToolbarPropsOverrides
            >,
            noResultsOverlay: NoResultsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableRowSelectionOnClick
          slotProps={{
            toolbar: {
              pageId: PageId.Projects,
              createCallback: handleOpen,
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Paper>

      {openForm && openProjectForm()}
    </>
  );
};

export default React.memo(Projects);
