import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

import { Box, Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import { ReceiptLong as TransactionsIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import {
  AccountModel,
  getAccounts,
  getTransactions,
  TransactionModel,
} from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { CalendarFormat } from 'utils/EpochConverter';

interface TransactionsListCardProps {
  filter: string;
}

const TransactionsListCard = (props: TransactionsListCardProps) => {
  const theme = useTheme();
  const { branch } = useAuth();

  const { getLoaderState, setLoaderState } = useLoader();

  const [transactions, setTransactions] = useState<TransactionModel[]>([]);
  const [accounts, setAccounts] = useState<AccountModel[]>([]);

  useEffect(() => {
    setLoaderState('transactionsListCard', true);
    Promise.all([
      getTransactions({
        branchId: branch?.id,
        pageSize: 10000,
        filter: props.filter,
      }),
      getAccounts({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
    ])
      .then(results => {
        setTransactions(results[0].rows);
        setAccounts(results[1].rows);
      })
      .finally(() => setLoaderState('transactionsListCard', false));
  }, [props]);

  return (
    <Card
      sx={{
        p: { xs: 2, lg: 3 },
        height: 400,
        display: 'grid',
        alignContent: transactions.length == 0 ? 'stretch' : 'flex-start',
      }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Transactions
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <TransactionsIcon fontSize="large" />
          </Icon>
        </Grid>
      </Grid>
      <Grid container overflow="scroll">
        {getLoaderState('transactionsListCard')
          ? _.times(5, index => {
              return (
                <Fragment key={index}>
                  <Grid item xs={6} pt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width={250}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1} justifyItems="right">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width={150}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={15}
                      width={200}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1} justifyItems="right">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={15}
                      width={100}
                    />
                  </Grid>
                </Fragment>
              );
            })
          : null}
        {!getLoaderState('transactionsListCard') && transactions.length == 0 ? (
          <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <img
              style={{
                width: '8vw',
                minWidth: '100px',
              }}
              src="/illustrations/noTransaction.svg"
            />
            <Typography p={3} color="grey">
              No Transactions
            </Typography>
          </Box>
        ) : null}
        {_.map(transactions, transaction => {
          return (
            <Grid container key={transaction.id}>
              <Grid item xs={8} pt={1}>
                <Typography variant="subtitle1">
                  {transaction.category}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="0.8rem"
                  color={theme.palette.secondary.light}>
                  {`${_.find(accounts, { id: transaction.accountId })?.name || ''}${_.find(accounts, { id: transaction.accountId }) ? ' - ' : ''}${transaction.transactionStatus}`}
                </Typography>
              </Grid>
              <Grid item xs={4} pt={1} textAlign="right">
                <Typography fontWeight={700}>
                  {(transaction.transactionType == 'Credit' ? '+ ' : '- ') +
                    formatCurrency(transaction.amount)}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="0.8rem"
                  color={theme.palette.secondary.light}>
                  {CalendarFormat(transaction.createdAt)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default React.memo(TransactionsListCard);
