import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import { AccountBalanceWallet as AccountBalanceWalletIcon } from '@mui/icons-material';

import { AccountModel, getAccounts } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';
import { DateTimeFormat } from 'utils/EpochConverter';

interface AccountsSummaryCardProps {
  filter: string;
  endDate: number;
}

const AccountsSummaryCard = (props: AccountsSummaryCardProps) => {
  const { branch, runWithPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [accounts, setAccounts] = useState<AccountModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('accountsSummaryCard', true);
        Promise.all([
          getAccounts({
            branchId: branch?.id,
            pageSize: 10000,
            fields: 'id,balance',
            filter: props.filter,
          }),
        ])
          .then(results => {
            setAccounts(results[0].rows);
          })
          .finally(() => {
            setLoaderState('accountsSummaryCard', false);
          });
      },
      PageId.Accounts,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" pb={0} fontWeight={700}>
            Total Balance
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <AccountBalanceWalletIcon fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" pb={1} color="secondary.light">
            {getLoaderState('accountsSummaryCard') ? (
              <Skeleton animation="wave" width={200} />
            ) : (
              'As of ' + DateTimeFormat(props.endDate)
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700}>
            {getLoaderState('accountsSummaryCard') ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <PermissionGuard
                pageId={PageId.Accounts}
                permissionType={PermissionType.View}
                noPermissionComponent={<>...</>}>
                {formatCurrency(
                  _.sumBy(accounts, item => Number(item.balance)),
                )}
              </PermissionGuard>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(AccountsSummaryCard);
