import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedEmployeeModel {
  rows: EmployeeModel[];
  total: number;
}

export interface EmployeeModel {
  id: number;
  branchId: number;
  name: string;
  email: string;
  password: string;
  aadhaar: string;
  pan: string;
  license: string;
  address: string;
  phone1: string;
  phone2: string;
  phone3: string;
  status: string;
  position: string;
  doj: number;
  account: number;
  salary: number;
  permissions: number[];
  notes: string;
  'files[]'?: File[];
  createdAt: number;
  updatedAt: number;
}

export const getEmployees = async (
  paramObj: object = {},
): Promise<PaginatedEmployeeModel> => {
  const response = await axios.get<PaginatedEmployeeModel>(`/employees`, {
    params: paramObj,
  });
  return response.data;
};

export const getEmployeeById = async (id: number): Promise<EmployeeModel> => {
  const response = await axios.get<EmployeeModel>(`/employees/${id}`);
  return response.data;
};

export const createEmployee = async (
  employeeData: Omit<EmployeeModel, 'id'>,
): Promise<EmployeeModel> => {
  const response = await axios.post<EmployeeModel>(`/employees`, employeeData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateEmployee = async (
  id: GridRowId,
  employeeData: Partial<EmployeeModel>,
): Promise<EmployeeModel> => {
  const response = await axios.put<EmployeeModel>(
    `/employees/${id}`,
    employeeData,
  );
  return response.data;
};

export const deleteEmployee = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/employees/${id}`);
};
