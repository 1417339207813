import React from 'react';
import { Typography } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';

const NoRowsOverlay = () => {
  return (
    <GridOverlay sx={{ flexDirection: 'column' }}>
      <img
        style={{ width: '8vw', minWidth: '100px' }}
        src="/illustrations/noData.svg"
      />
      <Typography p={3} color="grey">
        No data available
      </Typography>
    </GridOverlay>
  );
};

export default React.memo(NoRowsOverlay);
