import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import { ShoppingBag as ShoppingBagIcon } from '@mui/icons-material';

import { getTransactions, TransactionModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { DateFormat } from 'utils/EpochConverter';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';

interface DebitSummaryCardProps {
  filter: string;
  startDate: number;
}

const DebitSummaryCard = (props: DebitSummaryCardProps) => {
  const { branch, runWithPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [transactions, setTransactions] = useState<TransactionModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('debitSummaryCard', true);
        Promise.all([
          getTransactions({
            branchId: branch?.id,
            pageSize: 10000,
            fields: 'id,transactionType,category,amount,createdAt',
            filter: props.filter,
          }),
        ])
          .then(results => {
            setTransactions(results[0].rows);
          })
          .finally(() => setLoaderState('debitSummaryCard', false));
      },
      PageId.Transactions,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" pb={0} fontWeight={700}>
            Total Debit
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <ShoppingBagIcon fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" pb={1} color="secondary.light">
            {getLoaderState('debitSummaryCard') ? (
              <Skeleton animation="wave" width={200} />
            ) : (
              DateFormat(props.startDate) + ' - Now'
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700}>
            {getLoaderState('debitSummaryCard') ? (
              <Skeleton animation="wave" width={150} />
            ) : (
              <PermissionGuard
                pageId={PageId.Transactions}
                permissionType={PermissionType.View}
                noPermissionComponent={<>...</>}>
                {formatCurrency(
                  _.sumBy(
                    _.filter(transactions, { transactionType: 'Debit' }),
                    item => Number(item.amount),
                  ),
                )}
              </PermissionGuard>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(DebitSummaryCard);
