// import * as stackTrace from 'stack-trace';
// stack-trace currently lack TypeScript declaration files. Hence 'require'
// eslint-disable-next-line @typescript-eslint/no-var-requires

export enum PageId {
  Dashboard = 1,
  Projects = 2,
  WorkLogs = 3,
  Transactions = 4,
  Customers = 5,
  Suppliers = 6,
  Inventories = 7,
  Operations = 8,
  TimeSheets = 9,
  Reminders = 10,
  Accounts = 11,
  Vehicles = 12,
  Employees = 13,
}
