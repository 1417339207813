import React from 'react';

import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import { useAuth } from 'contexts/AuthContext';

interface PermissionGuardProps {
  pageId: PageId;
  permissionType: PermissionType | PermissionType[];
  children?: React.ReactNode;
  noPermissionComponent?: React.ReactNode;
}

const PermissionGuard: React.FC<PermissionGuardProps> = ({
  pageId,
  permissionType,
  children,
  noPermissionComponent,
}) => {
  const { hasPermission } = useAuth();

  if (!hasPermission(pageId, permissionType)) {
    return <>{noPermissionComponent}</>;
  }

  return <>{children}</>;
};

export default React.memo(PermissionGuard);
