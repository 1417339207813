import React from 'react';

import { Box, LinearProgress } from '@mui/material';

import { useLoader } from 'contexts/LoaderContext';

const Loader = () => {
  const { getLoaderState } = useLoader();

  return (
    // TODO: either of above two: CIRCULAR is page blocker LINEAR is non-blocker.
    // or we can have a 'type' in context and set it from where it is consumed.

    // <Backdrop
    //   sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
    //   open={loading}>
    //   <CircularProgress color="inherit" />
    // </Backdrop>

    getLoaderState('global') ? (
      <Box
        sx={{
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1301,
        }}>
        <LinearProgress />
      </Box>
    ) : null

    /**
     * Useful Information:
     *
     * Material-UI zIndex Values
     * =========================
     *
     * Material-UI defines a standard set of zIndex values for different components:
     * App Bar: zIndex: 1100
     * Drawer: zIndex: 1200
     * Modal/Backdrop/Dialog: zIndex: 1300
     *
     * Why 1301 for the LinearProgress?
     * Ensure Visibility: The zIndex: 1301 ensures that the LinearProgress is displayed above everything else.
     */
  );
};

export default React.memo(Loader);
