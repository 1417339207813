import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

const NoPermission = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75 }}>
        <Paper
          elevation={0}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: '-webkit-center',
          }}>
          <Helmet>
            <title>{'SKC - No Permission'}</title>
          </Helmet>
          <Grid>
            <Grid item xs={12} px={2} pb={5}>
              <img
                style={{ width: '20vw', minWidth: '300px' }}
                src="/illustrations/noPermission.svg"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={700} px={3}>
                Oops! Permission Required.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              p={2}
              pt={3}
              sx={{ width: '70%', minWidth: 300 }}>
              <Typography variant="subtitle1" color="grey" fontSize={16}>
                Your account currently doesn&apos;t have the required
                permissions to access this page.
              </Typography>
            </Grid>
            <Grid item xs={12} p={2}>
              <Button
                onClick={() => window.location.replace('/')}
                variant="contained">
                {'Go Home'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </motion.div>
    </AnimatePresence>
  );
};

export default React.memo(NoPermission);
