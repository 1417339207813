import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import {
  AccountModel,
  createReminder,
  CustomerModel,
  deleteReminder,
  EmployeeModel,
  getAccounts,
  getCustomers,
  getEmployees,
  getInventories,
  getProjects,
  getReminders,
  getSuppliers,
  getVehicles,
  InventoryModel,
  ProjectModel,
  ReminderModel,
  SupplierModel,
  updateReminder,
  VehicleModel,
} from 'apis';
import { useLoader } from 'contexts/LoaderContext';
import { useAuth } from 'contexts/AuthContext';
import ReminderForm from './ReminderForm';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { ReminderFormStatusOptions } from 'constants/DropDownOptions';
import { CurrentDateTimeUnix, DateTimeFormat } from 'utils/EpochConverter';
import NoResultsOverlay from 'components/TableGrid/NoResultsOverlay';
import NoRowsOverlay from 'components/TableGrid/NoRowsOverlay';
import { EMPTY_COLUMN } from 'constants/TableConstants';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import TableToolBar from 'components/TableGrid/TableToolBar';
import TableActions from 'components/TableGrid/TableActions';

export type FormModeType = 'create' | 'edit' | 'delete';

const Reminders = () => {
  const { branch, employee, runWithPermission, hasPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [selectedReminderID, setSelectedReminderID] = useState<GridRowId>();
  const [remindersData, setRemindersData] = useState<ReminderModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [accountsList, setAccountsList] = useState<AccountModel[]>([]);
  const [employeesList, setEmployeesList] = useState<EmployeeModel[]>([]);
  const [projectsList, setProjectsList] = useState<ProjectModel[]>([]);
  const [customersList, setCustomersList] = useState<CustomerModel[]>([]);
  const [suppliersList, setSuppliersList] = useState<SupplierModel[]>([]);
  const [vehiclesList, setVehiclesList] = useState<VehicleModel[]>([]);
  const [inventoriesList, setInventoriesList] = useState<InventoryModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('remindersTable', true);
        getReminders({
          branchId: branch?.id,
          page: page + 1,
          pageSize: pageSize,
          sort: sortColumn + ':' + sortOrder,
          filter: filterField + ':' + filterOperator + ':' + filterValue,
        })
          .then(response => {
            setRemindersData(response.rows);
            setRowCount(response.total);
          })
          .catch(error => console.error('Error Fetching Reminders: ', error))
          .finally(() => setLoaderState('remindersTable', false));
      },
      PageId.Reminders,
      PermissionType.View,
    );
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    Promise.all([
      getAccounts({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getEmployees({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getProjects({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getCustomers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getSuppliers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getVehicles({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,registration',
      }),
      getInventories({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
    ]).then(results => {
      setAccountsList(results[0].rows);
      setEmployeesList(results[1].rows);
      setProjectsList(results[2].rows);
      setCustomersList(results[3].rows);
      setSuppliersList(results[4].rows);
      setVehiclesList(results[5].rows);
      setInventoriesList(results[6].rows);
    });
  }, []);

  const handleAddReminder = (newReminder: Partial<ReminderModel>) => {
    setLoaderState('remindersForm', true);
    createReminder(newReminder as Omit<ReminderModel, 'id'>)
      .then(response => {
        setRemindersData(prevReminders => [...prevReminders, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding reminder: ', error))
      .finally(() => setLoaderState('remindersForm', false));
  };

  const handleEditReminder = (editedReminder: Partial<ReminderModel>) => {
    setLoaderState('remindersForm', true);
    if (selectedReminderID) {
      updateReminder(selectedReminderID, editedReminder)
        .then(response => {
          setRemindersData(
            _.chain(remindersData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(reminders => {
                const index = _.findIndex(
                  reminders,
                  rem => rem.id === selectedReminderID,
                );
                if (index !== -1) reminders[index] = response;
                return reminders;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing reminder: ', error))
        .finally(() => setLoaderState('remindersForm', false));
    }
  };

  const handleDeleteReminder = () => {
    if (selectedReminderID) {
      setLoaderState('remindersForm', true);
      deleteReminder(selectedReminderID)
        .then(() => {
          setRemindersData(
            _.reject(remindersData, {
              id: selectedReminderID,
            }) as ReminderModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting reminder: ', error))
        .finally(() => setLoaderState('remindersForm', false));
    }
  };

  const openReminderForm = () => {
    let title: string;
    let currentReminder: Partial<ReminderModel> = {
      branchId: branch?.id,
      accountId: 0,
      employeeId: employee?.id,
      projectId: 0,
      customerId: 0,
      supplierId: 0,
      vehicleId: 0,
      inventoryId: 0,
      name: '',
      notes: '',
      time: CurrentDateTimeUnix(),
      status: '',
    };
    let callback: (reminder: Partial<ReminderModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Reminder';
        callback = handleAddReminder;
        break;
      case 'edit':
        title = 'Edit Reminder';
        callback = handleEditReminder;
        currentReminder = _.chain(remindersData)
          .find(reminder => reminder.id === selectedReminderID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Reminder';
        callback = handleDeleteReminder;
        currentReminder = _.chain(remindersData)
          .find(reminder => reminder.id === selectedReminderID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Reminder';
        callback = handleAddReminder;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <ReminderForm
            mode={formMode}
            initFormState={currentReminder}
            accountsList={accountsList}
            employeesList={employeesList}
            projectsList={projectsList}
            customersList={customersList}
            suppliersList={suppliersList}
            vehiclesList={vehiclesList}
            inventoriesList={inventoriesList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedReminderID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedReminderID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      type: 'string',
    },
    {
      field: 'time',
      headerName: 'TIME',
      flex: 0.8,
      filterable: false,
      valueGetter: value => value && DateTimeFormat(value),
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(ReminderFormStatusOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(ReminderFormStatusOptions, {
          value: value as string,
        });
        return result ? result.label : EMPTY_COLUMN;
      },
    },
    TableActions({
      pageId: PageId.Reminders,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Reminders</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <DataGrid
          loading={getLoaderState('remindersTable')}
          rows={remindersData}
          columns={_.compact(columns)}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: TableToolBar as unknown as JSXElementConstructor<
              GridToolbarProps & ToolbarPropsOverrides
            >,
            noResultsOverlay: NoResultsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableRowSelectionOnClick
          slotProps={{
            toolbar: {
              pageId: PageId.Reminders,
              createCallback: handleOpen,
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Paper>

      {openForm && openReminderForm()}
    </>
  );
};

export default React.memo(Reminders);
