import React from 'react';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { PageId } from 'constants/PageId';
import PermissionGuard from 'components/common/PermissionGuard';
import { PermissionType } from 'utils/PermissionsConfig';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';

interface TableActionsProps {
  pageId: PageId;
  handleView?: (id: GridRowId) => void;
  handleEdit?: (id: GridRowId) => void;
  handleDelete?: (id: GridRowId) => void;
  hasPermission: (
    pageId: PageId,
    types: PermissionType | (PermissionType | null)[],
  ) => boolean;
}

const TableActions = (props: TableActionsProps): GridColDef | null => {
  const actionColumn: GridColDef = {
    field: 'actions',
    type: 'actions',
    headerName: 'ACTIONS',
    flex: 0.4,
    cellClassName: 'actions',
    getActions: ({ id }) => [
      props.handleView ? (
        <PermissionGuard
          key={id}
          pageId={props.pageId}
          permissionType={PermissionType.View}>
          <GridActionsCellItem
            key={id}
            icon={<VisibilityIcon />}
            label="View"
            onClick={() => props.handleView && props.handleView(id)}
          />
        </PermissionGuard>
      ) : (
        <></>
      ),
      props.handleEdit ? (
        <PermissionGuard
          key={id}
          pageId={props.pageId}
          permissionType={PermissionType.Modify}>
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => props.handleEdit && props.handleEdit(id)}
            color="inherit"
          />
        </PermissionGuard>
      ) : (
        <></>
      ),
      props.handleDelete ? (
        <PermissionGuard
          key={id}
          pageId={props.pageId}
          permissionType={PermissionType.Delete}>
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => props.handleDelete && props.handleDelete(id)}
          />
        </PermissionGuard>
      ) : (
        <></>
      ),
    ],
  };

  if (
    props.hasPermission(props.pageId, [
      props.handleView ? PermissionType.View : null,
      props.handleEdit ? PermissionType.Modify : null,
      props.handleDelete ? PermissionType.Delete : null,
    ])
  ) {
    return actionColumn;
  }

  return null;
};

export default TableActions;
