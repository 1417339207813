import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import { LoadingButton } from '@mui/lab';

import { FormModeType } from './Reminders';
import { useLoader } from 'contexts/LoaderContext';
import { ReminderFormStatusOptions } from 'constants/DropDownOptions';
import { DateTimeFormatString } from 'constants/DateTimeFormat';
import {
  AccountModel,
  CustomerModel,
  deletePageFile,
  EmployeeModel,
  FileResType,
  getPageFiles,
  InventoryModel,
  ProjectModel,
  ReminderModel,
  SupplierModel,
  updatePageFile,
  VehicleModel,
} from 'apis';
import AlertPopup, { AlertType } from 'components/AlertPopup/AlertPopup';
import { FileUpload } from 'components';

interface ReminderFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<ReminderModel>;
  accountsList: AccountModel[];
  employeesList: EmployeeModel[];
  projectsList: ProjectModel[];
  customersList: CustomerModel[];
  suppliersList: SupplierModel[];
  vehiclesList: VehicleModel[];
  inventoriesList: InventoryModel[];
  callbackFn: (reminder: Partial<ReminderModel>) => void;
  handleCloseFn: () => void;
}

const ReminderForm = (props: ReminderFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { getLoaderState, setLoaderState, setLoaderProgress } = useLoader();
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [apiFiles, setApiFiles] = useState<FileResType[]>([]);
  const [alertType, setAlertType] = useState<AlertType>('info');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);

  const disabled = props.mode === 'delete';

  if (props.mode === 'edit' || props.mode === 'delete') {
    useEffect(() => {
      setLoaderState('fileUpload', true);
      if (formState.id) {
        getPageFiles('reminders', formState.id)
          .then(response => {
            setApiFiles(response);
          })
          .catch(error => {
            setAlertType('error');
            setAlertMessage(error?.message);
            setOpenAlertPopup(true);
            console.error('Error fetching reminder file(s): ', error);
          })
          .finally(() => setLoaderState('fileUpload', false));
      }
    }, []);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files);
      if (props.mode === 'create') {
        handleLocalProjectFiles(filesArray);
      } else if (props.mode === 'edit') {
        setLoaderState('fileUpload', true);
        if (formState.id) {
          updatePageFile(
            'reminders',
            formState.id,
            { 'files[]': filesArray },
            setLoaderProgress,
          )
            .then(response => {
              setAlertType('success');
              setAlertMessage('File(s) uploaded');
              setOpenAlertPopup(true);
              setApiFiles(response);
            })
            .catch(error => {
              setAlertType('error');
              setAlertMessage(error?.message);
              setOpenAlertPopup(true);
              console.error('Error uploading reminder file(s): ', error);
            })
            .finally(() => setLoaderState('fileUpload', false));
        }
      }
    }
  };

  const handleFileDelete = (fileToDelete: File | FileResType) => {
    if (props.mode === 'create') {
      // Here DELETE happens locally.
      handleLocalProjectFiles(
        _.reject(localFiles, { name: fileToDelete.name }),
      );
    } else if (props.mode === 'edit') {
      // Here DELETE happens via API.
      setLoaderState('fileUpload', true);
      if (formState.id) {
        const file = fileToDelete as FileResType;
        deletePageFile('reminders', formState.id, file.signedId)
          .then(() => {
            setAlertType('success');
            setAlertMessage('File deleted');
            setOpenAlertPopup(true);
            setApiFiles(_.reject(apiFiles, { id: file.id }));
          })
          .catch(error => {
            setAlertType('error');
            setAlertMessage(error?.message);
            setOpenAlertPopup(true);
            console.error('Error deleting reminder file(s): ', error);
          })
          .finally(() => setLoaderState('fileUpload', false));
      }
    }
  };

  const handleLocalProjectFiles = (files: File[]) => {
    setFormState(prevState => ({ ...prevState, 'files[]': files }));
    setLocalFiles(files);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name: string) => (newDate: Moment | null) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: newDate?.unix(),
    }));
  };

  const handleAutocompleteChange = (name: string, value: number) => {
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<ReminderModel>);
  };

  const handleAlertPopupClose = () => {
    setOpenAlertPopup(false);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              name="name"
              autoFocus
              value={formState.name}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="status-select-label">
              {disabled ? 'Status' : 'Status *'}
            </InputLabel>
            <Select
              label="Status"
              labelId="status-select-label"
              id="status-select"
              name="status"
              value={formState.status}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(ReminderFormStatusOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimePicker
              label="Time"
              name="time"
              value={moment.unix(formState.time as unknown as number)}
              format={DateTimeFormatString}
              onChange={handleDateChange('time')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              noOptionsText="Loading..."
              getOptionLabel={option => option.name}
              value={_.find(props.accountsList, { id: formState?.accountId })}
              renderInput={params => <TextField {...params} label="Account" />}
              options={!props.accountsList ? [] : props.accountsList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('accountId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              noOptionsText="Loading..."
              getOptionLabel={option => option.name}
              value={_.find(props.employeesList, { id: formState?.employeeId })}
              renderInput={params => <TextField {...params} label="Employee" />}
              options={!props.employeesList ? [] : props.employeesList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('employeeId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              noOptionsText="Loading..."
              getOptionLabel={option => option.name}
              value={_.find(props.projectsList, { id: formState?.projectId })}
              renderInput={params => <TextField {...params} label="Project" />}
              options={!props.projectsList ? [] : props.projectsList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('projectId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              noOptionsText="Loading..."
              getOptionLabel={option => option.name}
              value={_.find(props.customersList, { id: formState?.customerId })}
              renderInput={params => <TextField {...params} label="Customer" />}
              options={!props.customersList ? [] : props.customersList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('customerId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              noOptionsText="Loading..."
              getOptionLabel={option => option.name}
              value={_.find(props.suppliersList, { id: formState?.supplierId })}
              renderInput={params => <TextField {...params} label="Supplier" />}
              options={!props.suppliersList ? [] : props.suppliersList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('supplierId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              noOptionsText="Loading..."
              getOptionLabel={option => option.registration}
              value={_.find(props.vehiclesList, { id: formState?.vehicleId })}
              renderInput={params => <TextField {...params} label="Vehicle" />}
              options={!props.vehiclesList ? [] : props.vehiclesList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('vehicleId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              noOptionsText="Loading..."
              getOptionLabel={option => option.name}
              value={_.find(props.inventoriesList, {
                id: formState?.inventoryId,
              })}
              renderInput={params => (
                <TextField {...params} label="Inventory" />
              )}
              options={!props.inventoriesList ? [] : props.inventoriesList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('inventoryId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FileUpload
            mode={props.mode}
            disabled={props.mode === 'delete'}
            localFiles={localFiles}
            apiFiles={apiFiles}
            handleFileChangeFn={handleFileChange}
            handleFileDeleteFn={handleFileDelete}
          />
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6 }}
            loading={getLoaderState('remindersForm')}>
            {btnName()}
          </LoadingButton>
        </Grid>

        {alertMessage && (
          <AlertPopup
            type={alertType}
            open={openAlertPopup}
            message={alertMessage}
            onClose={handleAlertPopupClose}
            autoHideDuration={3000}
          />
        )}
      </Grid>
    </Box>
  );
};

export default React.memo(ReminderForm);
