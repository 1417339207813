import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

import { Box, Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import { Inventory as InventoryIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { getInventories, InventoryModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { formatQuantity } from 'utils/NumberFormatter';

interface InventoriesListCardProps {
  filter: string;
}

const InventoriesListCard = (props: InventoriesListCardProps) => {
  const theme = useTheme();
  const { branch } = useAuth();

  const { getLoaderState, setLoaderState } = useLoader();

  const [inventories, setInventories] = useState<InventoryModel[]>([]);

  useEffect(() => {
    setLoaderState('inventoriesListCard', true);
    Promise.all([
      getInventories({
        branchId: branch?.id,
        pageSize: 10000,
        filter: props.filter,
      }),
    ])
      .then(results => {
        setInventories(results[0].rows);
      })
      .finally(() => setLoaderState('inventoriesListCard', false));
  }, []);

  return (
    <Card
      sx={{
        p: { xs: 2, lg: 3 },
        height: 400,
        display: 'grid',
        alignContent: inventories.length == 0 ? 'stretch' : 'flex-start',
      }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Inventories
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <InventoryIcon fontSize="large" />
          </Icon>
        </Grid>
      </Grid>
      <Grid container overflow="scroll">
        {getLoaderState('inventoriesListCard')
          ? _.times(5, index => {
              return (
                <Fragment key={index}>
                  <Grid item xs={6} pt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width={250}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1} justifyItems="right">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width={150}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={15}
                      width={200}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1} justifyItems="right">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={15}
                      width={100}
                    />
                  </Grid>
                </Fragment>
              );
            })
          : null}
        {!getLoaderState('inventoriesListCard') && inventories.length == 0 ? (
          <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <img
              style={{
                width: '8vw',
                minWidth: '100px',
              }}
              src="/illustrations/noInventory.svg"
            />
            <Typography p={3} color="grey">
              No Inventories
            </Typography>
          </Box>
        ) : null}
        {_.map(inventories, inventory => {
          return (
            <Grid container key={inventory.id}>
              <Grid item xs={8} pt={1}>
                <Typography variant="subtitle1">{inventory.name}</Typography>
                <Typography
                  variant="body1"
                  fontSize="0.8rem"
                  color={theme.palette.secondary.light}>
                  {inventory.category + ' - ' + inventory.code}
                </Typography>
              </Grid>
              <Grid item xs={4} pt={1} textAlign="right">
                <Typography fontWeight={700}>
                  {formatCurrency(inventory.price) + ' / ' + inventory.unit}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="0.8rem"
                  color={theme.palette.secondary.light}>
                  {formatQuantity(inventory.quantity) +
                    ' [' +
                    inventory.limit +
                    ']'}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default React.memo(InventoriesListCard);
