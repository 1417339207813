import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Box, Card, Grid, Icon, Typography } from '@mui/material';
import { Category } from '@mui/icons-material';

import { getOperations, getProjects, OperationModel, ProjectModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { CircularChart } from 'components';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';

interface InventoriesExpensePieChartCardProps {
  filter: string;
}

const InventoriesExpensePieChartCard = (
  props: InventoriesExpensePieChartCardProps,
) => {
  const { branch, runWithPermission } = useAuth();

  const { getLoaderState, setLoaderState } = useLoader();

  const [operations, setOperations] = useState<OperationModel[]>([]);
  const [projects, setProjects] = useState<ProjectModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('inventoriesExpensePieChartCard', true);
        Promise.all([
          getOperations({
            branchId: branch?.id,
            pageSize: 10000,
            filter: props.filter,
          }),
          getProjects({
            branchId: branch?.id,
            pageSize: 10000,
          }),
        ])
          .then(results => {
            setOperations(results[0].rows);
            setProjects(results[1].rows);
          })
          .finally(() =>
            setLoaderState('inventoriesExpensePieChartCard', false),
          );
      },
      PageId.Inventories,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card sx={{ p: { xs: 2, lg: 3 }, pb: 1 }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Projects
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <Category fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={12} height={400} pb={3}>
          {!getLoaderState('inventoriesExpensePieChartCard') &&
          operations.length == 0 ? (
            <Box
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <PermissionGuard
                pageId={PageId.Inventories}
                permissionType={PermissionType.View}
                noPermissionComponent={
                  <>
                    <img
                      style={{
                        width: '8vw',
                        minWidth: '100px',
                      }}
                      src="/illustrations/noPermission.svg"
                    />
                    <Typography p={3} color="grey">
                      No Permission
                    </Typography>
                  </>
                }>
                <img
                  style={{
                    width: '8vw',
                    minWidth: '100px',
                  }}
                  src="/illustrations/noProjects.svg"
                />
                <Typography p={3} color="grey">
                  No Projects
                </Typography>
              </PermissionGuard>
            </Box>
          ) : (
            <CircularChart
              loaderName={'inventoriesExpensePieChartCard'}
              data={_.map(
                _.groupBy(operations, 'projectId'),
                (items, name) => ({
                  label: _.chain(projects)
                    .find(item => item.id == Number(name))
                    .value().name,
                  value: _.sumBy(items, item => Number(item.quantity)),
                }),
              )}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(InventoriesExpensePieChartCard);
