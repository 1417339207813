import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import {
  AccountModel,
  createWorkLog,
  CustomerModel,
  deleteWorkLog,
  EmployeeModel,
  getAccounts,
  getCustomers,
  getEmployees,
  getInventories,
  getProjects,
  getReminders,
  getSuppliers,
  getVehicles,
  getWorkLogs,
  InventoryModel,
  ProjectModel,
  ReminderModel,
  SupplierModel,
  updateWorkLog,
  VehicleModel,
  WorkLogModel,
} from 'apis';
import { useLoader } from 'contexts/LoaderContext';
import { useAuth } from 'contexts/AuthContext';
import { DateTimeFormat } from 'utils/EpochConverter';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { WorkLogFormStatusOptions } from 'constants/DropDownOptions';
import WorkLogForm from './WorkLogForm';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { formatQuantity } from 'utils/NumberFormatter';
import NoResultsOverlay from 'components/TableGrid/NoResultsOverlay';
import NoRowsOverlay from 'components/TableGrid/NoRowsOverlay';
import { EMPTY_COLUMN } from 'constants/TableConstants';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import TableToolBar from 'components/TableGrid/TableToolBar';
import TableActions from 'components/TableGrid/TableActions';

export type FormModeType = 'create' | 'edit' | 'delete';

const WorkLogs = () => {
  const { branch, employee, runWithPermission, hasPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [selectedWorkLogID, setSelectedWorkLogID] = useState<GridRowId>();
  const [workLogsData, setWorkLogsData] = useState<WorkLogModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [accountsList, setAccountsList] = useState<AccountModel[]>([]);
  const [employeesList, setEmployeesList] = useState<EmployeeModel[]>([]);
  const [projectsList, setProjectsList] = useState<ProjectModel[]>([]);
  const [customersList, setCustomersList] = useState<CustomerModel[]>([]);
  const [suppliersList, setSuppliersList] = useState<SupplierModel[]>([]);
  const [vehiclesList, setVehiclesList] = useState<VehicleModel[]>([]);
  const [inventoriesList, setInventoriesList] = useState<InventoryModel[]>([]);
  const [remindersList, setRemindersList] = useState<ReminderModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('workLogsTable', true);
        getWorkLogs({
          branchId: branch?.id,
          page: page + 1,
          pageSize: pageSize,
          sort: sortColumn + ':' + sortOrder,
          filter: filterField + ':' + filterOperator + ':' + filterValue,
        })
          .then(response => {
            setWorkLogsData(response.rows);
            setRowCount(response.total);
          })
          .catch(error => console.error('Error Fetching Projects: ', error))
          .finally(() => setLoaderState('workLogsTable', false));
      },
      PageId.WorkLogs,
      PermissionType.View,
    );
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    Promise.all([
      getAccounts({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getEmployees({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getProjects({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getCustomers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getSuppliers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getVehicles({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,registration',
      }),
      getInventories({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,supplierId,name,unit,price',
      }),
      getReminders({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
    ]).then(results => {
      setAccountsList(results[0].rows);
      setEmployeesList(results[1].rows);
      setProjectsList(results[2].rows);
      setCustomersList(results[3].rows);
      setSuppliersList(results[4].rows);
      setVehiclesList(results[5].rows);
      setInventoriesList(results[6].rows);
      setRemindersList(results[7].rows);
    });
  }, []);

  const handleAddWorkLog = (newWorkLog: Partial<WorkLogModel>) => {
    setLoaderState('workLogsForm', true);
    createWorkLog(newWorkLog as Omit<WorkLogModel, 'id'>)
      .then(response => {
        setWorkLogsData(prevWorkLogs => [...prevWorkLogs, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding work log: ', error))
      .finally(() => setLoaderState('workLogsForm', false));
  };

  const handleEditWorkLog = (editedWorkLog: Partial<WorkLogModel>) => {
    setLoaderState('workLogsForm', true);
    if (selectedWorkLogID) {
      updateWorkLog(selectedWorkLogID, editedWorkLog)
        .then(response => {
          setWorkLogsData(
            _.chain(workLogsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(workLogs => {
                const index = _.findIndex(
                  workLogs,
                  trans => trans.id === selectedWorkLogID,
                );
                if (index !== -1) workLogs[index] = response;
                return workLogs;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing work log: ', error))
        .finally(() => setLoaderState('workLogsForm', false));
    }
  };

  const handleDeleteWorkLog = () => {
    if (selectedWorkLogID) {
      setLoaderState('workLogsForm', true);
      deleteWorkLog(selectedWorkLogID)
        .then(() => {
          setWorkLogsData(
            _.reject(workLogsData, {
              id: selectedWorkLogID,
            }) as WorkLogModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting work log: ', error))
        .finally(() => setLoaderState('workLogsForm', false));
    }
  };

  const openWorkLogForm = () => {
    let title: string;
    let currentWorkLog: Partial<WorkLogModel> = {
      branchId: branch?.id,
      employeeId: employee?.id,
      verifierId: 0,
      accountId: 0,
      customerId: 0,
      supplierId: 0,
      projectId: 0,
      inventoryId: 0,
      vehicleId: 0,
      reminderId: 0,
      status: '',
      workType: '',
      workSubType: '',
      workQuantity: 0,
      workUnit: '',
      workCost: 0,
      notes: '',
    };
    let callback: (workLog: Partial<WorkLogModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Work Log';
        callback = handleAddWorkLog;
        break;
      case 'edit':
        title = 'Edit Work Log';
        callback = handleEditWorkLog;
        currentWorkLog = _.chain(workLogsData)
          .find(workLog => workLog.id === selectedWorkLogID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Work Log';
        callback = handleDeleteWorkLog;
        currentWorkLog = _.chain(workLogsData)
          .find(workLog => workLog.id === selectedWorkLogID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Work Log';
        callback = handleAddWorkLog;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <WorkLogForm
            mode={formMode}
            initFormState={currentWorkLog}
            accountsList={accountsList}
            employeesList={employeesList}
            projectsList={projectsList}
            customersList={customersList}
            suppliersList={suppliersList}
            vehiclesList={vehiclesList}
            inventoriesList={inventoriesList}
            remindersList={remindersList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedWorkLogID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedWorkLogID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    {
      field: 'projectId',
      headerName: 'PROJECT',
      flex: 0.6,
      type: 'singleSelect',
      valueOptions: _.map(projectsList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(projectsList, record => {
          return record.id == value;
        });
        return result ? result.name : EMPTY_COLUMN;
      },
    },
    {
      field: 'createdAt',
      headerName: 'DATE',
      flex: 0.6,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateTimeFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'supplierId',
      headerName: 'SUPPLIER',
      flex: 0.6,
      type: 'singleSelect',
      valueOptions: _.map(suppliersList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(suppliersList, record => {
          return record.id == value;
        });
        return result ? result.name : EMPTY_COLUMN;
      },
    },
    {
      field: 'inventoryId',
      headerName: 'INVENTORY',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(inventoriesList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(inventoriesList, record => {
          return record.id == value;
        });
        return result ? result.name : EMPTY_COLUMN;
      },
    },
    {
      field: 'workQuantity',
      headerName: 'VOLUME',
      flex: 0.2,
      type: 'number',
      valueGetter: value => value && formatQuantity(value),
    },
    {
      field: 'workUnit',
      headerName: 'UNIT',
      flex: 0.2,
      type: 'string',
    },
    {
      field: 'workCost',
      headerName: 'COST',
      flex: 0.3,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 0.3,
      type: 'singleSelect',
      valueOptions: _.map(WorkLogFormStatusOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(WorkLogFormStatusOptions, {
          value: value as string,
        });
        return result ? result.label : EMPTY_COLUMN;
      },
    },
    TableActions({
      pageId: PageId.WorkLogs,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Work Logs</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <DataGrid
          loading={getLoaderState('workLogsTable')}
          rows={workLogsData}
          columns={_.compact(columns)}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: TableToolBar as unknown as JSXElementConstructor<
              GridToolbarProps & ToolbarPropsOverrides
            >,
            noResultsOverlay: NoResultsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableRowSelectionOnClick
          slotProps={{
            toolbar: {
              pageId: PageId.WorkLogs,
              createCallback: handleOpen,
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Paper>

      {openForm && openWorkLogForm()}
    </>
  );
};

export default React.memo(WorkLogs);
