const NumberFormatter = Intl.NumberFormat('en-IN', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const formatQuantity = (value: number | undefined) => {
  if (value == null) {
    return '0';
  }
  return NumberFormatter.format(value).replace(/^(\D+)/, '$1 ');
};

export const formatTaxPercentage = (value: number | undefined) => {
  return formatQuantity(value) + '%';
};
