import React from 'react';
import { bouncy } from 'ldrs';

import { Paper, useMediaQuery } from '@mui/material';
import { PieChart } from '@mui/x-charts';

import { LoaderNameType, useLoader } from 'contexts/LoaderContext';
import _ from 'lodash';

interface CircularChartType {
  loaderName: LoaderNameType;
  data: CircularChartData[];
}

interface CircularChartData {
  label: string;
  value: number;
}

const CircularChart = (props: CircularChartType) => {
  let largeScreen = useMediaQuery('(min-width:1500px)');
  if (largeScreen) {
    if (_.uniqBy(props.data, 'label').length > 12) {
      largeScreen = false;
    }
  }

  const { getLoaderState } = useLoader();

  bouncy.register();

  return getLoaderState(props.loaderName) ? (
    <Paper
      elevation={0}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <l-bouncy size="45" speed="1.75" color="black"></l-bouncy>
    </Paper>
  ) : (
    <PieChart
      loading={getLoaderState(props.loaderName)}
      colors={[
        'black',
        'grey',
        'wheat',
        'tan',
        'lightblue',
        'lightcoral',
        'cornflowerblue',
        'crimson',
        'darkolivegreen',
        'gold',
        'olivedrab',
        'orange',
      ]}
      margin={{
        right: largeScreen ? 100 : 0,
        bottom: largeScreen ? 0 : 150,
      }}
      slotProps={{
        legend: {
          direction: largeScreen ? 'column' : 'row',
          position: {
            vertical: largeScreen ? 'middle' : 'bottom',
            horizontal: largeScreen ? 'right' : 'middle',
          },
        },
      }}
      series={[
        {
          paddingAngle: 1,
          innerRadius: '60%',
          outerRadius: '75%',
          cornerRadius: 6,
          data: props.data,
        },
      ]}
    />
  );
};

export default React.memo(CircularChart);
