import React, { JSXElementConstructor, useEffect, useState } from 'react';

import {
  DataGrid,
  GridColDef,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import { pageSizeOptions } from 'constants/PaginationOptions';
import _ from 'lodash';
import TableToolBar from './TableToolBar';
import NoResultsOverlay from './NoResultsOverlay';
import NoRowsOverlay from './NoRowsOverlay';
import { PageId } from 'constants/PageId';
import { LoaderNameType, useLoader } from 'contexts/LoaderContext';
import { TableControls } from './TableControls';
import { AccountModel, PaginatedAccountModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { PermissionType } from 'utils/PermissionsConfig';
import { FormModeType } from 'pages/Accounts/Accounts';

interface TableGridProps {
  pageId: PageId;
  loaderName: LoaderNameType;
  columns: (GridColDef | null)[];
  rows: AccountModel[];
  setRows: (data: AccountModel[]) => void;
  fetchFunction: (paramObj: object) => Promise<PaginatedAccountModel>;
  createCallback: (mode: FormModeType) => void;
}

const TableGrid = (props: TableGridProps) => {
  const { branch, runWithPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [tableControls, setTableControls] = useState<TableControls>({
    page: 0,
    pageSize: pageSizeOptions[2],
    sortColumn: '',
    sortOrder: '',
    filterField: '',
    filterOperator: '',
    filterValue: '',
  });

  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState(props.loaderName, true);
        props
          .fetchFunction({
            branchId: branch?.id,
            page: tableControls.page + 1,
            pageSize: tableControls.pageSize,
            sort: tableControls.sortColumn + ':' + tableControls.sortOrder,
            filter:
              tableControls.filterField +
              ':' +
              tableControls.filterOperator +
              ':' +
              tableControls.filterValue,
          })
          .then(response => {
            props.setRows(response.rows);
            setRowCount(response.total);
          })
          .catch(error =>
            console.error(
              `Error fetching ${_.startCase(_.toLower(props.loaderName))}: `,
              error,
            ),
          )
          .finally(() => setLoaderState(props.loaderName, false));
      },
      props.pageId,
      PermissionType.View,
    );
  }, [tableControls]);

  return (
    <DataGrid
      loading={getLoaderState(props.loaderName)}
      rows={props.rows}
      columns={_.compact(props.columns)}
      pagination
      paginationMode="server"
      rowCount={rowCount}
      pageSizeOptions={pageSizeOptions}
      onPaginationModelChange={model => {
        setTableControls(prevState => ({
          ...prevState,
          page: model.page,
          pageSize: model.pageSize,
        }));
      }}
      sortingMode="server"
      onSortModelChange={model => {
        _.map(model, sortItem => {
          setTableControls(prevState => ({
            ...prevState,
            sortColumn: sortItem.field,
            sortOrder: sortItem.sort,
          }));
        });
      }}
      filterMode="server"
      filterDebounceMs={1000}
      onFilterModelChange={model => {
        _.map(model.items, filterModel => {
          if (filterModel.value != undefined) {
            setTableControls(prevState => ({
              ...prevState,
              filterField: filterModel.field,
              filterOperator: filterModel.operator,
              filterValue: filterModel.value,
            }));
          }
        });
        if (_.isEmpty(model.items)) {
          setTableControls(prevState => ({
            ...prevState,
            filterField: '',
            filterOperator: '',
            filterValue: ']',
          }));
        }
      }}
      slots={{
        // TODO: Scope for future improvements. MUI limitation.
        toolbar: TableToolBar as unknown as JSXElementConstructor<
          GridToolbarProps & ToolbarPropsOverrides
        >,
        noResultsOverlay: NoResultsOverlay,
        noRowsOverlay: NoRowsOverlay,
      }}
      disableRowSelectionOnClick
      slotProps={{
        toolbar: {
          pageId: props.pageId,
          createCallback: props.createCallback,
        },
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
    />
  );
};

export default React.memo(TableGrid);
