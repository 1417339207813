import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedWorkLogModel {
  rows: WorkLogModel[];
  total: number;
}

export interface WorkLogModel {
  id: number;
  branchId: number;
  employeeId: number;
  verifierId: number;
  accountId: number;
  customerId: number;
  supplierId: number;
  projectId: number;
  inventoryId: number;
  vehicleId: number;
  reminderId: number;
  status: string;
  workType: string;
  workSubType: string;
  workQuantity: number;
  workUnit: string;
  workCost: number;
  notes: string;
  'files[]'?: File[];
  createdAt: number;
  updatedAt: number;
}

export const getWorkLogs = async (
  paramObj: object = {},
): Promise<PaginatedWorkLogModel> => {
  const response = await axios.get<PaginatedWorkLogModel>(`/work_logs`, {
    params: paramObj,
  });
  return response.data;
};

export const getWorkLogById = async (id: number): Promise<WorkLogModel> => {
  const response = await axios.get<WorkLogModel>(`/work_logs/${id}`);
  return response.data;
};

export const createWorkLog = async (
  workLogData: Omit<WorkLogModel, 'id'>,
): Promise<WorkLogModel> => {
  const response = await axios.post<WorkLogModel>(`/work_logs`, workLogData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateWorkLog = async (
  id: GridRowId,
  workLogData: Partial<WorkLogModel>,
): Promise<WorkLogModel> => {
  const response = await axios.put<WorkLogModel>(
    `/work_logs/${id}`,
    workLogData,
  );
  return response.data;
};

export const deleteWorkLog = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/work_logs/${id}`);
};
