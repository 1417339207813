import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Grid } from '@mui/material';

import { CurrentDateTimeUnix, CurrentMonthUnix } from 'utils/EpochConverter';
import ProjectsListCard from 'pages/Projects/ProjectsListCard';
import RemindersListCard from 'pages/Reminders/RemindersListCard';
import TransactionsPieChartCard from 'pages/Transactions/TransactionsPieChartCard';
import TransactionsBarChartCard from 'pages/Transactions/TransactionsBarChartCard';
import ProjectsSummaryCard from 'pages/Projects/ProjectsSummaryCard';
import DebitSummaryCard from 'pages/Transactions/DebitSummaryCard';
import CreditSummaryCard from 'pages/Transactions/CreditSummaryCard';
import AccountsSummaryCard from 'pages/Accounts/AccountsSummaryCard';

const Dashboard = () => {
  const startDate = CurrentMonthUnix();
  const endDate = CurrentDateTimeUnix();

  return (
    <>
      <Helmet>
        <title>SKC - Dashboard</title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <AccountsSummaryCard filter="" endDate={endDate} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <CreditSummaryCard
            filter={'createdAt:onOrAfter:' + startDate}
            startDate={startDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DebitSummaryCard
            filter={'createdAt:onOrAfter:' + startDate}
            startDate={startDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ProjectsSummaryCard filter="" />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TransactionsBarChartCard
            filter={'createdAt:onOrAfter:' + startDate}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TransactionsPieChartCard
            filter={'createdAt:onOrAfter:' + startDate}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <ProjectsListCard filter="status:not:Closed" />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <RemindersListCard filter="status:is:Enabled" />
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(Dashboard);
