import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import {
  createCustomer,
  CustomerModel,
  deleteCustomer,
  getCustomers,
  updateCustomer,
} from 'apis/CustomerApi';
import { useLoader } from 'contexts/LoaderContext';
import CustomerForm from './CustomerForm';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { useAuth } from 'contexts/AuthContext';
import NoResultsOverlay from 'components/TableGrid/NoResultsOverlay';
import NoRowsOverlay from 'components/TableGrid/NoRowsOverlay';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import TableToolBar from 'components/TableGrid/TableToolBar';
import TableActions from 'components/TableGrid/TableActions';

export type FormModeType = 'create' | 'edit' | 'delete';

const Customers = () => {
  const { branch, runWithPermission, hasPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [selectedCustomerID, setSelectedCustomerID] = useState<GridRowId>();
  const [customersData, setCustomersData] = useState<CustomerModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('customersTable', true);
        getCustomers({
          branchId: branch?.id,
          page: page + 1,
          pageSize: pageSize,
          sort: sortColumn + ':' + sortOrder,
          filter: filterField + ':' + filterOperator + ':' + filterValue,
        })
          .then(response => {
            setCustomersData(response.rows);
            setRowCount(response.total);
          })
          .catch(error => console.error('Error Fetching Customers: ', error))
          .finally(() => setLoaderState('customersTable', false));
      },
      PageId.Customers,
      PermissionType.View,
    );
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  const handleAddCustomer = (newCustomer: Partial<CustomerModel>) => {
    setLoaderState('customersForm', true);
    createCustomer(newCustomer as Omit<CustomerModel, 'id'>)
      .then(response => {
        setCustomersData(prevCustomers => [...prevCustomers, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding customer: ', error))
      .finally(() => setLoaderState('customersForm', false));
  };

  const handleEditCustomer = (editedCustomer: Partial<CustomerModel>) => {
    setLoaderState('customersForm', true);
    if (selectedCustomerID) {
      updateCustomer(selectedCustomerID, editedCustomer)
        .then(response => {
          setCustomersData(
            _.chain(customersData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(customers => {
                const index = _.findIndex(
                  customers,
                  acc => acc.id === selectedCustomerID,
                );
                if (index !== -1) customers[index] = response;
                return customers;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing customer: ', error))
        .finally(() => setLoaderState('customersForm', false));
    }
  };

  const handleDeleteCustomer = () => {
    if (selectedCustomerID) {
      setLoaderState('customersForm', true);
      deleteCustomer(selectedCustomerID)
        .then(() => {
          setCustomersData(
            _.reject(customersData, {
              id: selectedCustomerID,
            }) as CustomerModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting customer: ', error))
        .finally(() => setLoaderState('customersForm', false));
    }
  };

  const openCustomerForm = () => {
    let title: string;
    let currentCustomer: Partial<CustomerModel> = {
      branchId: branch?.id,
      name: '',
      phone1: '',
      phone2: '',
      phone3: '',
      address: '',
      gstIn: '',
      balance: 0,
      limit: 0,
      gst: 0,
      iGst: 0,
      cGst: 0,
      sGst: 0,
      notes: '',
    };
    let callback: (customer: Partial<CustomerModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Customer';
        callback = handleAddCustomer;
        break;
      case 'edit':
        title = 'Edit Customer';
        callback = handleEditCustomer;
        currentCustomer = _.chain(customersData)
          .find(transaction => transaction.id === selectedCustomerID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Customer';
        callback = handleDeleteCustomer;
        currentCustomer = _.chain(customersData)
          .find(transaction => transaction.id === selectedCustomerID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Customer';
        callback = handleAddCustomer;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <CustomerForm
            mode={formMode}
            initFormState={currentCustomer}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleView = (id: GridRowId) => {
    // Securely open a new tab
    const newWindow = window.open(
      `/app/customers/${id}`,
      '_blank',
      'noopener,noreferrer',
    );

    if (newWindow) {
      newWindow.opener = null; // Prevent reverse tabnabbing
    }
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedCustomerID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedCustomerID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'phone1',
      headerName: 'PHONE',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'gstIn',
      headerName: 'GSTIN',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'limit',
      headerName: 'LIMIT',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'balance',
      headerName: 'BALANCE',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'gst',
      headerName: 'GST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    TableActions({
      pageId: PageId.Customers,
      handleView: handleView,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Customers</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <DataGrid
          loading={getLoaderState('customersTable')}
          rows={customersData}
          columns={_.compact(columns)}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: TableToolBar as unknown as JSXElementConstructor<
              GridToolbarProps & ToolbarPropsOverrides
            >,
            noResultsOverlay: NoResultsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableRowSelectionOnClick
          slotProps={{
            toolbar: {
              pageId: PageId.Customers,
              createCallback: handleOpen,
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Paper>

      {openForm && openCustomerForm()}
    </>
  );
};

export default React.memo(Customers);
