import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid';

import {
  createSupplier,
  deleteSupplier,
  getSuppliers,
  SupplierModel,
  updateSupplier,
} from 'apis/SupplierApi';
import { useLoader } from 'contexts/LoaderContext';
import SupplierForm from './SupplierForm';
import { formatCurrency } from 'utils/CurrencyFormatter';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { useAuth } from 'contexts/AuthContext';
import NoResultsOverlay from 'components/TableGrid/NoResultsOverlay';
import NoRowsOverlay from 'components/TableGrid/NoRowsOverlay';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import TableToolBar from 'components/TableGrid/TableToolBar';
import TableActions from 'components/TableGrid/TableActions';

export type FormModeType = 'create' | 'edit' | 'delete';

const Suppliers = () => {
  const { branch, runWithPermission, hasPermission } = useAuth();
  const { getLoaderState, setLoaderState } = useLoader();

  const [selectedSupplierID, setSelectedSupplierID] = useState<GridRowId>();
  const [suppliersData, setSuppliersData] = useState<SupplierModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('suppliersTable', true);
        getSuppliers({
          branchId: branch?.id,
          page: page + 1,
          pageSize: pageSize,
          sort: sortColumn + ':' + sortOrder,
          filter: filterField + ':' + filterOperator + ':' + filterValue,
        })
          .then(response => {
            setSuppliersData(response.rows);
            setRowCount(response.total);
          })
          .catch(error => console.error('Error Fetching Suppliers: ', error))
          .finally(() => setLoaderState('suppliersTable', false));
      },
      PageId.Suppliers,
      PermissionType.View,
    );
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  const handleAddSupplier = (newSupplier: Partial<SupplierModel>) => {
    setLoaderState('suppliersForm', true);
    createSupplier(newSupplier as Omit<SupplierModel, 'id'>)
      .then(response => {
        setSuppliersData(prevSuppliers => [...prevSuppliers, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding supplier: ', error))
      .finally(() => setLoaderState('suppliersForm', false));
  };

  const handleEditSupplier = (editedSupplier: Partial<SupplierModel>) => {
    setLoaderState('suppliersForm', true);
    if (selectedSupplierID) {
      updateSupplier(selectedSupplierID, editedSupplier)
        .then(response => {
          setSuppliersData(
            _.chain(suppliersData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(suppliers => {
                const index = _.findIndex(
                  suppliers,
                  acc => acc.id === selectedSupplierID,
                );
                if (index !== -1) suppliers[index] = response;
                return suppliers;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing supplier: ', error))
        .finally(() => setLoaderState('suppliersForm', false));
    }
  };

  const handleDeleteSupplier = () => {
    if (selectedSupplierID) {
      setLoaderState('suppliersForm', true);
      deleteSupplier(selectedSupplierID)
        .then(() => {
          setSuppliersData(
            _.reject(suppliersData, {
              id: selectedSupplierID,
            }) as SupplierModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting supplier: ', error))
        .finally(() => setLoaderState('suppliersForm', false));
    }
  };

  const openSupplierForm = () => {
    let title: string;
    let currentSupplier: Partial<SupplierModel> = {
      branchId: branch?.id,
      name: '',
      phone1: '',
      phone2: '',
      phone3: '',
      address: '',
      gstIn: '',
      balance: 0,
      limit: 0,
      gst: 0,
      iGst: 0,
      cGst: 0,
      sGst: 0,
      notes: '',
    };
    let callback: (supplier: Partial<SupplierModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Supplier';
        callback = handleAddSupplier;
        break;
      case 'edit':
        title = 'Edit Supplier';
        callback = handleEditSupplier;
        currentSupplier = _.chain(suppliersData)
          .find(transaction => transaction.id === selectedSupplierID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Supplier';
        callback = handleDeleteSupplier;
        currentSupplier = _.chain(suppliersData)
          .find(transaction => transaction.id === selectedSupplierID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Supplier';
        callback = handleAddSupplier;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <SupplierForm
            mode={formMode}
            initFormState={currentSupplier}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleView = (id: GridRowId) => {
    // Securely open a new tab
    const newWindow = window.open(
      `/app/suppliers/${id}`,
      '_blank',
      'noopener,noreferrer',
    );

    if (newWindow) {
      newWindow.opener = null; // Prevent reverse tabnabbing
    }
  };

  const handleEdit = (id: GridRowId) => {
    setSelectedSupplierID(id);
    handleOpen('edit');
  };

  const handleDelete = (id: GridRowId) => {
    setSelectedSupplierID(id);
    handleOpen('delete');
  };

  const columns: (GridColDef | null)[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'phone1',
      headerName: 'PHONE',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'gstIn',
      headerName: 'GSTIN',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'limit',
      headerName: 'LIMIT',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'balance',
      headerName: 'BALANCE',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'gst',
      headerName: 'GST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    TableActions({
      pageId: PageId.Suppliers,
      handleView: handleView,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
      hasPermission: hasPermission,
    }),
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Suppliers</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400 }}>
        <DataGrid
          loading={getLoaderState('suppliersTable')}
          rows={suppliersData}
          columns={_.compact(columns)}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: TableToolBar as unknown as JSXElementConstructor<
              GridToolbarProps & ToolbarPropsOverrides
            >,
            noResultsOverlay: NoResultsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          disableRowSelectionOnClick
          slotProps={{
            toolbar: {
              pageId: PageId.Suppliers,
              createCallback: handleOpen,
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Paper>

      {openForm && openSupplierForm()}
    </>
  );
};

export default React.memo(Suppliers);
