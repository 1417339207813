export const ShortenFileName = (name: string, maxLength: number) => {
  const ext = name.split('.').pop() || ''; // Get file extension
  const nameWithoutExt = name.slice(0, -(ext?.length + 1)); // Remove extension from the name

  if (nameWithoutExt.length <= maxLength) return name; // No need to shorten if it's within the limit

  const partLength = Math.floor((maxLength - 3) / 2); // Calculate the length for start and end parts (subtract 3 for "...")
  const start = nameWithoutExt.slice(0, partLength);
  const end = nameWithoutExt.slice(-partLength);

  return `${start}...${end}.${ext}`;
};
