import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Skeleton,
  Typography,
} from '@mui/material';
import { Layers as ProjectsIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { getProjects, ProjectModel } from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { CurrentDateTimeUnix } from 'utils/EpochConverter';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';
import PermissionGuard from 'components/common/PermissionGuard';

interface ProjectsListCardProps {
  filter: string;
}

const ProjectsListCard = (props: ProjectsListCardProps) => {
  const theme = useTheme();
  const { branch, runWithPermission } = useAuth();

  const { getLoaderState, setLoaderState } = useLoader();

  const [projects, setProjects] = useState<ProjectModel[]>([]);

  useEffect(() => {
    runWithPermission(
      () => {
        setLoaderState('projectsListCard', true);
        Promise.all([
          getProjects({
            branchId: branch?.id,
            pageSize: 10000,
            filter: props.filter,
          }),
        ])
          .then(results => {
            setProjects(results[0].rows);
          })
          .finally(() => setLoaderState('projectsListCard', false));
      },
      PageId.Projects,
      PermissionType.View,
    );
  }, [props]);

  return (
    <Card
      sx={{
        p: { xs: 2, lg: 3 },
        height: 400,
        display: 'grid',
        alignContent: projects.length == 0 ? 'stretch' : 'flex-start',
      }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Projects
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <ProjectsIcon fontSize="large" />
          </Icon>
        </Grid>
      </Grid>
      <Grid container overflow="scroll">
        {getLoaderState('projectsListCard')
          ? _.times(5, index => {
              return (
                <Fragment key={index}>
                  <Grid item xs={6} pt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width={250}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1} justifyItems="right">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width={150}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={15}
                      width={200}
                    />
                  </Grid>
                  <Grid item xs={6} pt={1} justifyItems="right">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={15}
                      width={100}
                    />
                  </Grid>
                </Fragment>
              );
            })
          : null}
        {!getLoaderState('projectsListCard') && projects.length == 0 ? (
          <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <PermissionGuard
              pageId={PageId.Projects}
              permissionType={PermissionType.View}
              noPermissionComponent={
                <>
                  <img
                    style={{
                      width: '8vw',
                      minWidth: '100px',
                    }}
                    src="/illustrations/noPermission.svg"
                  />
                  <Typography p={3} color="grey">
                    No Permission
                  </Typography>
                </>
              }>
              <img
                style={{
                  width: '8vw',
                  minWidth: '100px',
                }}
                src="/illustrations/noTransaction.svg"
              />
              <Typography p={3} color="grey">
                No Projects
              </Typography>
            </PermissionGuard>
          </Box>
        ) : null}
        {_.map(projects, project => {
          let budget = Math.round(
            (project.totalExpense / project.totalBudget) * 100,
          );
          budget = budget < 0 ? 0 : budget;
          budget = budget > 100 ? 100 : budget;
          let time = Math.round(
            ((CurrentDateTimeUnix() - project.startDate) /
              (project.endDate - project.startDate)) *
              100,
          );
          time = time < 0 ? 0 : time;
          time = time > 100 ? 100 : time;
          return (
            <Grid container key={project.id}>
              <Grid item xs={8} sm={10} pt={1}>
                <Typography variant="subtitle1">{project.name}</Typography>
                <Typography
                  variant="body1"
                  fontSize="0.8rem"
                  color={theme.palette.secondary.light}>
                  {project.status}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} pt={1} textAlign="right">
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress
                    variant="determinate"
                    color="primary"
                    value={budget}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Typography variant="caption" component="div">
                      {budget + '%'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2} sm={1} pt={1} textAlign="right">
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress
                    variant="determinate"
                    color="primary"
                    value={time}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Typography variant="caption" component="div">
                      {time + '%'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default React.memo(ProjectsListCard);
