import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

import axios from 'apis/AxiosConfig';
import { BranchModel, EmployeeModel } from 'apis';
import {
  generatePermissionCode,
  PermissionType,
} from 'utils/PermissionsConfig';
import _ from 'lodash';
import { PageId } from 'constants/PageId';

export interface LoginReqType {
  email: string;
  password: string;
  branchCode: string;
  deviceId: string;
}

interface LoginResType {
  employee: EmployeeModel;
  branch: BranchModel;
}

interface AuthContextType {
  isAuthenticated: boolean;
  authLoading: boolean;
  authCheck: () => Promise<boolean>;
  hasPermission: (
    pageId: PageId,
    types: PermissionType | (PermissionType | null)[],
  ) => boolean;
  runWithPermission: (
    callback: (...args: unknown[]) => unknown,
    pageId: PageId,
    types: PermissionType | (PermissionType | null)[],
  ) => unknown;
  login: (loginReq: LoginReqType) => Promise<boolean>;
  logout: () => Promise<boolean>;
  branch: BranchModel | undefined;
  employee: EmployeeModel | undefined;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [branch, setBranch] = useState<BranchModel | undefined>();
  const [employee, setEmployee] = useState<EmployeeModel | undefined>();

  const resetAuth = (): boolean => {
    setIsAuthenticated(false);
    setBranch(undefined);
    setEmployee(undefined);
    return false;
  };

  useEffect(() => {
    if (!isAuthenticated) {
      authCheck();
    }
  }, []);

  const authCheck = async (): Promise<boolean> => {
    const minimumDelay = new Promise<void>(resolve =>
      setTimeout(resolve, 1500),
    );
    setAuthLoading(true);
    try {
      const response = await axios.post<LoginResType>('/api/auth/check');
      await Promise.all([minimumDelay]);
      if (response.status == 200) {
        setIsAuthenticated(true);
        setBranch(response.data.branch);
        setEmployee(response.data.employee);
        return true;
      }
      return resetAuth();
    } catch (error) {
      console.error(error);
      await Promise.all([minimumDelay]);
      return resetAuth();
    } finally {
      await Promise.all([minimumDelay]);
      setAuthLoading(false);
    }
  };

  const login = async (loginReq: LoginReqType): Promise<boolean> => {
    const minimumDelay = new Promise<void>(resolve =>
      setTimeout(resolve, 1500),
    );
    setAuthLoading(true);
    try {
      const response = await axios.post<LoginResType>(
        '/api/auth/login',
        loginReq,
      );
      await Promise.all([minimumDelay]);
      if (response.status == 200) {
        setIsAuthenticated(true);
        setBranch(response.data.branch);
        setEmployee(response.data.employee);
        return true;
      }
      return resetAuth();
    } catch (error) {
      console.error(error);
      await Promise.all([minimumDelay]);
      return resetAuth();
    } finally {
      await Promise.all([minimumDelay]);
      setAuthLoading(false);
    }
  };

  const logout = async (): Promise<boolean> => {
    try {
      const response = await axios.post<LoginResType>('/api/auth/logout');
      if (response.status == 200) {
        resetAuth();
        return true;
      }
      return resetAuth();
    } catch (error) {
      console.error(error);
      return resetAuth();
    } finally {
      setAuthLoading(false);
    }
  };

  const hasPermission = (
    pageId: PageId,
    types: PermissionType | (PermissionType | null)[],
  ): boolean => {
    const requiredTypes = _.compact(_.castArray(types));
    const requiredCodes = _.map(requiredTypes, type =>
      generatePermissionCode(pageId, type),
    );

    return _.some(requiredCodes, code =>
      _.includes(employee?.permissions, code),
    );
  };

  const runWithPermission = (
    callback: (...args: unknown[]) => unknown,
    pageId: PageId,
    types: PermissionType | (PermissionType | null)[],
  ): unknown => {
    const typesArray = Array.isArray(types) ? _.compact(types) : [types];
    if (hasPermission(pageId, typesArray)) {
      return callback();
    } else {
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authLoading,
        authCheck,
        hasPermission,
        runWithPermission,
        login,
        logout,
        branch,
        employee,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
