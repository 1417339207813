import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Box, Card, Grid, Icon, Typography } from '@mui/material';
import { Analytics as WorkCategoryIcon } from '@mui/icons-material';

import {
  getInventories,
  getOperations,
  InventoryModel,
  OperationModel,
} from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { CircularChart } from 'components';
import { formatQuantity } from 'utils/NumberFormatter';
import PermissionGuard from 'components/common/PermissionGuard';
import { PageId } from 'constants/PageId';
import { PermissionType } from 'utils/PermissionsConfig';

interface OperationsPieChartCardProps {
  filter: string;
}

const OperationsPieChartCard = (props: OperationsPieChartCardProps) => {
  const { branch } = useAuth();

  const { setLoaderState, getLoaderState } = useLoader();

  const [operations, setOperations] = useState<OperationModel[]>([]);
  const [inventories, setInventories] = useState<InventoryModel[]>([]);

  useEffect(() => {
    setLoaderState('operationsPieChartCard', true);
    Promise.all([
      getOperations({
        branchId: branch?.id,
        pageSize: 10000,
        filter: props.filter,
      }),
      getInventories({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name,supplierId,unit,price',
      }),
    ])
      .then(results => {
        setOperations(results[0].rows);
        setInventories(results[1].rows);
      })
      .finally(() => setLoaderState('operationsPieChartCard', false));
  }, [props]);

  return (
    <Card sx={{ p: { xs: 2, lg: 3 }, pb: 1 }}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5" fontWeight={700}>
            Consumables
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Icon fontSize="large">
            <WorkCategoryIcon fontSize="large" />
          </Icon>
        </Grid>
        <Grid item xs={12} height={400} pb={3}>
          {!getLoaderState('operationsPieChartCard') &&
          operations.length == 0 ? (
            <Box
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <PermissionGuard
                pageId={PageId.Operations}
                permissionType={PermissionType.View}
                noPermissionComponent={
                  <>
                    <img
                      style={{
                        width: '8vw',
                        minWidth: '100px',
                      }}
                      src="/illustrations/noPermission.svg"
                    />
                    <Typography p={3} color="grey">
                      No Permission
                    </Typography>
                  </>
                }>
                <img
                  style={{
                    width: '8vw',
                    minWidth: '100px',
                  }}
                  src="/illustrations/noOperations.svg"
                />
                <Typography p={3} color="grey">
                  No Operations
                </Typography>
              </PermissionGuard>
            </Box>
          ) : (
            <CircularChart
              loaderName={'operationsPieChartCard'}
              data={_.map(
                _.groupBy(operations, 'inventoryId'),
                (items, category) => ({
                  label:
                    _.find(inventories, {
                      id: Number.parseInt(category),
                    })?.name +
                      ' [' +
                      formatQuantity(
                        _.sumBy(items, item => Number(item.quantity)),
                      ) +
                      ']' || '',
                  value: _.sumBy(items, item => Number(item.price)),
                }),
              )}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(OperationsPieChartCard);
