import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedAccountModel {
  rows: AccountModel[];
  total: number;
}

export interface AccountModel {
  id: number;
  branchId: number;
  name: string;
  code: string;
  balance: number;
  minimumBalance: number;
  gst: number;
  iGst: number;
  cGst: number;
  sGst: number;
  details: string;
  notes: string;
  'files[]'?: File[];
  createdAt: number;
  updatedAt: number;
}

export const getAccounts = async (
  paramObj: object = {},
): Promise<PaginatedAccountModel> => {
  const response = await axios.get<PaginatedAccountModel>(`/accounts`, {
    params: paramObj,
  });
  return response.data;
};

export const getAccountById = async (
  id: number | string,
): Promise<AccountModel> => {
  const response = await axios.get<AccountModel>(`/accounts/${id}`);
  return response.data;
};

export const createAccount = async (
  accountData: Omit<AccountModel, 'id'>,
): Promise<AccountModel> => {
  const response = await axios.post<AccountModel>(`/accounts`, accountData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateAccount = async (
  id: GridRowId,
  accountData: Partial<AccountModel>,
): Promise<AccountModel> => {
  const response = await axios.put<AccountModel>(
    `/accounts/${id}`,
    accountData,
  );
  return response.data;
};

export const deleteAccount = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/accounts/${id}`);
};
