import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Logout as LogoutIcon,
  Info as InfoIcon,
  Timeline as TimelineIcon,
  CurrencyRupee as RupeeIcon,
} from '@mui/icons-material';

import {
  CustomerModel,
  FileResType,
  getCustomerById,
  getPageFiles,
  getProjectById,
  ProjectModel,
} from 'apis';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { CurrentDateTimeUnix, DateFormat } from 'utils/EpochConverter';
import { formatCurrency } from 'utils/CurrencyFormatter';
import {
  calculateProgress,
  calculateRangeProgress,
} from 'utils/ProgressCalculator';
import AlertPopup, { AlertType } from 'components/AlertPopup/AlertPopup';
import { Copyright } from 'components/atoms';
import FilePreview from 'components/FilePreview/FilePreview';
import RemindersListCard from 'pages/Reminders/RemindersListCard';
import TransactionsListCard from 'pages/Transactions/TransactionsListCard';
import OperationsListCard from 'pages/Operations/OperationsListCard';
import TransactionsPieChartCard from 'pages/Transactions/TransactionsPieChartCard';
import OperationsPieChartCard from 'pages/Operations/OperationsPieChartCard';

const ProjectDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoaderState } = useLoader();
  const { branch, employee, logout } = useAuth();
  const [searchParams] = useSearchParams();

  const projectId = useParams().id || '';
  const customerId = searchParams.get('customerId') || '';

  const [project, setProject] = useState<ProjectModel>();
  const [customer, setCustomer] = useState<CustomerModel>();
  const [apiFiles, setApiFiles] = useState<FileResType[]>([]);

  const [alertType, setAlertType] = useState<AlertType>('info');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (projectId) {
      setLoaderState('projectDetails', true);
      Promise.all([
        getProjectById(projectId),
        getCustomerById(customerId),
        getPageFiles('projects', parseInt(projectId)),
      ])
        .then(results => {
          setProject(results[0]);
          setCustomer(results[1]);
          setApiFiles(results[2]);
        })
        .catch(error => {
          if (error.response.status == 404) {
            // Triggers catch-all route.
            navigate('/notFound');
            return;
          }
          setAlertType('error');
          setAlertMessage(error?.message);
          setOpenAlertPopup(true);
        })
        .finally(() => setLoaderState('projectDetails', false));
    }
  }, []);

  const handleAlertPopupClose = () => {
    setOpenAlertPopup(false);
  };

  return (
    <>
      <Helmet>
        <title>
          {project
            ? 'SKC - ' + project.viewId + ' (' + project.viewId + ')'
            : 'SKC'}
        </title>
      </Helmet>

      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}>
              {project ? project.name + ' (' + project.viewId + ')' : 'SKC'}
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{employee?.name.charAt(0).toUpperCase()}</Avatar>
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="projectDetail-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                <MenuItem sx={{ display: 'block', width: '250px' }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{ textOverflow: 'ellipsis' }}>
                        {employee?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} pt={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ height: '1.6em' }}>
                        {employee?.position}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      pt={1}
                      sx={{ alignContent: 'center', textAlign: 'right' }}>
                      <Typography variant="body2" color="grey">
                        {branch?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setLoaderState('global', true);
                    logout().finally(() => {
                      setLoaderState('global', false);
                      navigate('/');
                    });
                  }}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Grid container sx={{ mt: 6 }} spacing={2} p={2}>
          <Grid item xs={12} lg={4}>
            <Card sx={{ p: 2 }}>
              <Grid container>
                <Grid item xs={10} pb={1}>
                  <Typography variant="h5" fontWeight={700}>
                    Details
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" pb={1}>
                  <Icon fontSize="large">
                    <InfoIcon fontSize="large" />
                  </Icon>
                </Grid>
                <Grid item xs={4} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={8} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {project ? project.status : <Skeleton animation="wave" />}
                  </Typography>
                </Grid>
                <Grid item xs={4} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={8} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {project ? project.location : <Skeleton animation="wave" />}
                  </Typography>
                </Grid>
                <Grid item xs={4} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Customer
                  </Typography>
                </Grid>
                <Grid item xs={8} py={0.25}>
                  <Typography noWrap variant="body1" textAlign="right">
                    {customer ? customer.name : <Skeleton animation="wave" />}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card sx={{ p: 2 }}>
              <Grid container>
                <Grid item xs={10} pb={1}>
                  <Typography variant="h5" fontWeight={700}>
                    Timeline
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" pb={1}>
                  <Icon fontSize="large">
                    <TimelineIcon fontSize="large" />
                  </Icon>
                </Grid>
                <Grid item xs={4} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Starting
                  </Typography>
                </Grid>
                <Grid item xs={8} py={0.25}>
                  <Typography variant="body1" textAlign="right">
                    {project ? (
                      DateFormat(project.startDate)
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Ending
                  </Typography>
                </Grid>
                <Grid item xs={8} py={0.25}>
                  <Typography variant="body1" textAlign="right">
                    {project ? (
                      DateFormat(project.endDate)
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Progress
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25} alignContent="center">
                  {project ? (
                    <LinearProgress
                      sx={{ height: 0.5 }}
                      variant="determinate"
                      value={calculateRangeProgress(
                        CurrentDateTimeUnix(),
                        project.startDate,
                        project.endDate,
                      )}
                    />
                  ) : (
                    <Skeleton animation="wave" />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card sx={{ p: 2 }}>
              <Grid container>
                <Grid item xs={10} pb={1}>
                  <Typography variant="h5" fontWeight={700}>
                    Accounts
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" pb={1}>
                  <Icon fontSize="large">
                    <RupeeIcon fontSize="large" />
                  </Icon>
                </Grid>
                <Grid item xs={4} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Budget
                  </Typography>
                </Grid>
                <Grid item xs={8} py={0.25}>
                  <Typography variant="body1" textAlign="right">
                    {project ? (
                      formatCurrency(project.totalBudget)
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    Expense
                  </Typography>
                </Grid>
                <Grid item xs={8} py={0.25}>
                  <Typography variant="body1" textAlign="right">
                    {project ? (
                      formatCurrency(project.totalExpense)
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25}>
                  <Typography
                    variant="button"
                    color={theme.palette.secondary.light}>
                    USAGE
                  </Typography>
                </Grid>
                <Grid item xs={6} py={0.25} alignContent="center">
                  {project ? (
                    <LinearProgress
                      sx={{ height: 0.5 }}
                      variant="determinate"
                      value={calculateProgress(
                        project.totalExpense,
                        project.totalBudget,
                      )}
                    />
                  ) : (
                    <Skeleton animation="wave" />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <OperationsPieChartCard filter={`projectId:is:${projectId}`} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TransactionsPieChartCard filter={`projectId:is:${projectId}`} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TransactionsListCard filter={`projectId:is:${projectId}`} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OperationsListCard filter={`projectId:is:${projectId}`} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <RemindersListCard filter={`projectId:is:${projectId}`} />
          </Grid>
          <Grid item xs={12}>
            <FilePreview files={apiFiles} />
          </Grid>
          <Grid item xs={12}>
            <Copyright />
          </Grid>
        </Grid>
      </Box>

      {alertMessage && (
        <AlertPopup
          type={alertType}
          open={openAlertPopup}
          message={alertMessage}
          onClose={handleAlertPopupClose}
          autoHideDuration={3000}
        />
      )}
    </>
  );
};

export default React.memo(ProjectDetail);
